import React, { useEffect } from "react";
import "./LeaderBoard.css";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { getleaderBoard } from "../../../../../API/API";
import { store } from "../../../../../redux/redux";
import { GET_LEADERBOARD } from "../../../../../redux/actions/userDataAction";

export default function LeaderBoard({ onClose }) {
  const userDataReducer = useSelector((state) => state.UserReducer.userData);
  const leaderBoardReducer = useSelector(
    (state) => state.UserReducer.leaderBoard.Leaderboard
  );

  useEffect(() => {
    getleaderBoard(userDataReducer.UUID).then((e) => {
      store.dispatch(GET_LEADERBOARD(e));
    });
  }, []);
  return (
    <div className="leaderBoard_root">
      <button
        className="leaderBoard_CloseBTN"
        onClick={() => {
          onClose();
        }}
      >
        <CloseIcon fontSize="small" style={{ color: "#333333" }} />
      </button>
      <header style={{ fontSize: "2rem" }}>LEADERBOARD</header>
      <div className="leaderBoard_Container">
        <div className="leaderboard_columns">
          <header>RANK</header>
          <div className="leaderboard_columns">
            {leaderBoardReducer?.map(
              (e) =>
                e.totalScore !== 999999999 && (
                  <text
                    style={{
                      color:
                        userDataReducer.FirstName == e.FirstName
                          ? "#EB5A02"
                          : "#757575",
                      fontWeight:
                        userDataReducer.FirstName == e.FirstName ? "bold" : 400,
                    }}
                  >
                    {e.UserRank}
                  </text>
                )
            )}
            {store.getState().UserReducer.leaderBoard.UserData?.UserRank > 10 &&
              store.getState().UserReducer.leaderBoard.UserData?.totalScore !==
                999999999 && (
                <text
                  style={{
                    color: "#EB5A02",
                    fontWeight: "bold",
                  }}
                >
                  {store.getState().UserReducer.leaderBoard.UserData.UserRank}
                </text>
              )}
          </div>
        </div>
        <div className="leaderboard_columns">
          <header>NAME</header>
          <div className="leaderboard_columns">
            {leaderBoardReducer?.map(
              (e) =>
                e.totalScore !== 999999999 && (
                  <text
                    style={{
                      color:
                        userDataReducer.FirstName == e.FirstName
                          ? "#EB5A02"
                          : "#757575",
                      fontWeight:
                        userDataReducer.FirstName == e.FirstName ? "bold" : 400,
                    }}
                  >
                    {e.FirstName}
                  </text>
                )
            )}
            {store.getState().UserReducer.leaderBoard.UserData?.UserRank > 10 &&
              store.getState().UserReducer.leaderBoard.UserData?.totalScore !==
                999999999 && (
                <text
                  style={{
                    color: "#EB5A02",
                    fontWeight: "bold",
                  }}
                >
                  {store.getState().UserReducer.leaderBoard.UserData.FirstName}
                </text>
              )}
          </div>
        </div>
        <div className="leaderboard_columns">
          <header>TIME</header>
          <div className="leaderboard_columns">
            {leaderBoardReducer?.map(
              (e) =>
                e.totalScore !== 999999999 && (
                  <text
                    style={{
                      color:
                        userDataReducer.FirstName == e.FirstName
                          ? "#EB5A02"
                          : "#757575",
                      fontWeight:
                        userDataReducer.FirstName == e.FirstName ? "bold" : 400,
                    }}
                  >
                    {Math.floor(e.totalScore / 60)}m :
                    {Math.floor(e.totalScore % 60)}s :
                    {Math.floor((e.totalScore * 100) % 99)}ms
                  </text>
                )
            )}
            {store.getState().UserReducer.leaderBoard.UserData?.UserRank > 10 &&
              store.getState().UserReducer.leaderBoard.UserData?.totalScore !==
                999999999 && (
                <text
                  style={{
                    color: "#EB5A02",
                    fontWeight: "bold",
                  }}
                >
                  {store.getState().UserReducer.leaderBoard.UserData.totalScore}
                </text>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
