import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { contact } from "../../../API/API";
import "./Section5.css";
import validator from "validator";

function Section5() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");
  const [messageSent, setmessageSent] = useState(false);
  const [loading, setloading] = useState(false);
  const [invalidMail, setinvalidMail] = useState(false);

  const reset = () => {
    setname("");
    setemail("");
    setsubject("");
    setmessage("");
  };
  return (
    <div className="section5_root">
      <div className="section5_container_1">
        <div className="img"></div>
      </div>
      <div className="section5_container_2">
        <>
          <header>Drop up a message</header>
          <text style={{ color: "white" }}>
            Are you looking for inspiration, do you have a question or already a
            great idea? Get in touch with one of our experts for a free
            consultation.
          </text>
        </>
        <div className="input_container">
          <input
            value={name}
            placeholder="Your Full Name"
            type={"text"}
            onChange={(e) => {
              setname(e.target.value);
            }}
          ></input>
          {invalidMail && <label style={{ color: "red" }}>*Invalid Mail</label>}
          <input
            placeholder="Your Email Address"
            value={email}
            type={"text"}
            onChange={(e) => {
              const email = e.target.value;
              setinvalidMail(!validator.isEmail(email));
              setemail(email);
            }}
          ></input>
          <input
            placeholder="Subject"
            value={subject}
            type={"text"}
            onChange={(e) => {
              setsubject(e.target.value);
            }}
          ></input>
          <textarea
            rows={5}
            value={message}
            placeholder="Message"
            onChange={(e) => {
              setmessage(e.target.value);
            }}
          ></textarea>
          {messageSent ? (
            "Thank you for your message."
          ) : (
            <button
              disabled={
                name == "" ||
                email == "" ||
                message == "" ||
                subject == "" ||
                loading ||
                invalidMail
              }
              className="filled-Btn BTN custom-btn"
              onClick={() => {
                setloading(true);
                contact(email, name, message, subject)
                  .then(() => {
                    setloading(false);
                    setmessageSent(true);
                    reset();
                  })
                  .catch(() => {
                    setloading(false);
                  });
              }}
            >
              {loading ? (
                <CircularProgress size={18} style={{ color: "white" }} />
              ) : (
                "Send Message"
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Section5;
