import React from "react";
import { useNavigate } from "react-router-dom";
import "./Section1.css";

function Section1() {
  const navigate = useNavigate();
  return (
    <>
      <div className="section1-container">
        <h1 className="header1">Welcome to the</h1>
        <h1 className="header2">Metaverse Experience</h1>
        <button
          style={{ marginTop: "1em" }}
          className="BTN"
          onClick={() => {
            navigate("/login");
          }}
        >
          Lets Begin
        </button>
      </div>
    </>
  );
}

export default Section1;
