import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { joinChat } from "../../Agora/AgoraChatService/agoraChat";
import { getleaderBoard } from "../../API/API";
import { redirecttoLandingPage } from "../../constants/constants";
import { GET_LEADERBOARD } from "../../redux/actions/userDataAction";
import { store } from "../../redux/redux";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import ExperienceUI from "./ExperienceUI/ExperienceUI";
import Tutorial from "./ExperienceUI/UI_Components/Tutorial/Tutorial";
import Viewer from "./Viewer";

function Experience({ auth }) {
  const [isLoading, setisLoading] = useState(false);
  const [LoadingPercent, setLoadingPercent] = useState(0);
  const [perspectiveChange, setPerspectiveChange] = useState(true);
  const [openModal, setopenModal] = useState(true);
  const [width, setwidth] = useState(null);
  const [modalWidth, setmodalWidth] = useState("md");
  const [isiframe, setisiframe] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const userDataReducer = useSelector((state) => state.UserReducer.userData);

  const onClose = () => {
    setopenModal(false);
  };
  const [modalContent, setmodalContent] = useState(
    <Tutorial onClose={onClose} />
  );

  useEffect(() => {
    redirecttoLandingPage(auth);
    if (isiframe && modalContent.includes("https://pc-building-game.web.app")) {
      getleaderBoard(userDataReducer.UUID).then((e) => {
        store.dispatch(GET_LEADERBOARD(e));
      });
    }
  }, [openModal]);

  const ref = useRef();

  const startAnim = (name) => {
    ref.current.startAnimation(name);
  };

  const takeSelfie = () => {
    ref.current.takeSelfie();
  };

  const switchCam = () => {
    ref.current.switchCam();
  };

  const setupStadium = (name, setdisplayMessages) => {
    ref.current.setupStadium(name, setdisplayMessages);
  };
  const setupLounge = (name, setdisplayMessages) => {
    ref.current.setupLounge(name, setdisplayMessages);
  };
  const setupFanZone = (name, setdisplayMessages) => {
    ref.current.setupFanZone(name, setdisplayMessages);
  };

  useEffect(() => {
    joinChat(store.getState().UserReducer.userData.Room_Id);
    getleaderBoard(userDataReducer.UUID).then((e) => {
      store.dispatch(GET_LEADERBOARD(e));
    });
  }, []);
  return (
    <div>
      <Viewer
        setisLoading={setisLoading}
        ref={ref}
        setPerspectiveChange={setPerspectiveChange}
        perspectiveChange={perspectiveChange}
        takeSelfie={takeSelfie}
        setopenModal={setopenModal}
        setisiframe={setisiframe}
        setmodalWidth={setmodalWidth}
        setmodalContent={setmodalContent}
        setLoadingPercent={setLoadingPercent}
        setFirstTime={setFirstTime}
      />
      {isLoading && (
        <LoadingScreen firstTime={firstTime} loadingPercent={LoadingPercent} />
      )}
      {!isLoading && (
        <ExperienceUI
          width={width}
          modalContent={modalContent}
          setmodalContent={setmodalContent}
          setwidth={setwidth}
          startAnim={startAnim}
          setupStadium={setupStadium}
          setupLounge={setupLounge}
          setupFanZone={setupFanZone}
          modalWidth={modalWidth}
          setmodalWidth={setmodalWidth}
          setPerspectiveChange={setPerspectiveChange}
          perspectiveChange={perspectiveChange}
          switchCam={switchCam}
          setopenModal={setopenModal}
          openModal={openModal}
          takeSelfie={takeSelfie}
          isiframe={isiframe}
          setisiframe={setisiframe}
        />
      )}
    </div>
  );
}

export default Experience;
