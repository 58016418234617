import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import { messageReducer } from "./reducer/agoraChatReducer";
import { videoReducer } from "./reducer/agoraVideoReducer";
import { userReducer } from "./reducer/userDataReducer";
import { avatarReducer } from "./reducer/avatarReducer";

let middleware = [];
if (process.env.NODE_ENV === "development") {
  middleware = [...middleware, thunk, logger];
} else {
  middleware = [...middleware, thunk];
}

const combinedReducer = combineReducers({
  UserReducer: userReducer,
  VideoReducer: videoReducer,
  MessageReducer: messageReducer,
  AvatarReducer: avatarReducer,
});
export const store = createStore(
  combinedReducer,
  applyMiddleware(...middleware)
);
