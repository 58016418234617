const userInitialState = {
  userData: {},
  zoneData: "Stadium",
  userCount: 0,
  leaderBoard: [],
};

export const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case "ADD_USER_INFO": {
      return {
        ...state,
        userData: action.data,
      };
    }
    case "UPDATE_ZONE": {
      return { ...state, zoneData: action.data };
    }
    case "UPDATE_USER_COUNT": {
      return {
        ...state,
        userCount: action.data,
      };
    }
    case "GET_LEADERBOARD": {
      return {
        ...state,
        leaderBoard: action.data,
      };
    }
    default: {
      return state;
    }
  }
};
