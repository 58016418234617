export const ADD_USER_INFO = (userData) => ({
  type: "ADD_USER_INFO",
  data: userData,
});

export const UPDATE_ZONE = (userData) => ({
  type: "UPDATE_ZONE",
  data: userData,
});
export const UPDATE_USER_COUNT = (userData) => ({
  type: "UPDATE_USER_COUNT",
  data: userData,
});
export const GET_LEADERBOARD = (userData) => ({
  type: "GET_LEADERBOARD",
  data: userData,
});
