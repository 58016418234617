import * as BABYLON from "babylonjs";
import { io } from "socket.io-client";
import "babylonjs-loaders";
import { store } from "../../redux/redux";
import {
  addNamePlate,
  applyJerseyToLocalPlayer,
  localPlayer,
  setCharacter,
} from "./Player";
import { session_Ended } from "../../API/API";
import { landingUrl } from "../../constants/constants";
import { UPDATE_USER_COUNT } from "../../redux/actions/userDataAction";
import { MiscellaneousServices } from "@mui/icons-material";

const EndPoint = "https://gigabyte.metaqube.ai";

export const socket = io(EndPoint, {
  transports: ["websocket"],
  upgrade: false,
});

export let AllUsers = [];
let currentScene = null;

let runningAnim;
let runningBackwardsAnim;

export const spawnRemotePlayer = async (userId, name, scene, avatarConfig) => {
  let gender = null;
  if (avatarConfig.Gender === 0) {
    gender = "male_final.glb";
  } else {
    gender = "female.glb";
  }
  await BABYLON.SceneLoader.ImportMeshAsync("", "/", gender, scene, null).then(
    (loadedMesh) => {
      const remotePlayer = loadedMesh.meshes[0];
      remotePlayer.position = new BABYLON.Vector3(-0.1, 1.4, 19.4);
      remotePlayer.name = userId;

      //animations
      remotePlayer.animations.push(...loadedMesh.animationGroups);
      remotePlayer.animations[0].play(true);
      remotePlayer.scaling = new BABYLON.Vector3(0.17, 0.17, -0.17);

      remotePlayer.metadata = { videoAdded: false };

      runningAnim = remotePlayer.animations.find(
        (anim) => anim.name === "Running"
      );

      runningBackwardsAnim = remotePlayer.animations.find(
        (anim) => anim.name === "Running Backwards"
      );

      //nameplate
      addNamePlate(name, remotePlayer, scene, false);

      //avatarConfig
      setCharacter(remotePlayer, avatarConfig);

      //anim
      remotePlayer.onAfterWorldMatrixUpdateObservable.add(() => {
        // runningAnim.play(false);
      });

      addAgoraVideo(
        userId,
        store.getState().VideoReducer.remoteUsers[userId]?.videoTrack
      );

      return remotePlayer;
    }
  );
};

export const joinRoom = (scene, avatarConfig, room) => {
  if (!socket.connected) {
    socket.connect();
  }
  currentScene = scene;
  const user_ID = store.getState().UserReducer.userData.UUID;
  const user_Name = store.getState().UserReducer.userData.FirstName;
  const room_ID = room;
  currentScene = scene;

  socket.on("sendCurrentPos", () => {
    const user = scene.getMeshByName(user_ID);
    if (user) {
      const newPosition = {
        x: user.position.x,
        y: user.position.y,
        z: user.position.z,
      };
      const newRotation = user.rotation.y;
      socket.emit("pos", {
        userId: user_ID,
        room: room_ID,
        pos: newPosition,
        rot: newRotation,
      });
    }
  });

  socket.emit(
    "join",
    {
      userId: user_ID,
      name: user_Name,
      room: room_ID,
      avatarConfig: avatarConfig,
    },
    ({ responseCode, users }) => {
      if (responseCode == "DP_SESSION") {
        console.log("Session Already Exists");
        window.location.href = landingUrl;
        return;
      }
      AllUsers = users;
      store.dispatch(UPDATE_USER_COUNT(AllUsers.length));
      AllUsers.forEach((element) => {
        if (element.userId !== user_ID) {
          spawnRemotePlayer(
            element.userId,
            element.name,
            scene,
            element.avatarConfig
          ).then((remotePlayer) => {
            // remoteplayer.position = new BABYLON.Vector3(-0.1, 1.4, 19.4);
            socket.emit("pingCurrentPos", { room_ID: room_ID });
          });
        }
      });

      const user = scene.getMeshByName(user_ID);

      if (user) {
        user.onAfterWorldMatrixUpdateObservable.add(() => {
          const newPosition = {
            x: user.position.x,
            y: user.position.y,
            z: user.position.z,
          };
          const newRotation = user.rotation.y;
          socket.emit("pos", {
            userId: user_ID,
            room: room_ID,
            pos: newPosition,
            rot: newRotation,
          });
        });
      }
    }
  );

  socket.on("jerseySwitched", ({ userId, jerseyUrl }) => {
    const mesh = scene.getMeshByName(userId);
    if (mesh) {
      applyJerseyToLocalPlayer(mesh, jerseyUrl, scene);
    }
  });

  socket.on("emoteUsed", ({ userId, animName }) => {
    const mesh = scene.getMeshByName(userId);
    if (mesh) {
      for (let index = 0; index < mesh.animations.length; index++) {
        const element = mesh.animations[index];
        if (element.name === animName) {
          element.play(false);
          element.onAnimationGroupEndObservable.add(() => {
            mesh.animations[0].play(true);
          });
        } else {
          element.stop();
        }
      }
    }
  });

  socket.on("newuserjoined", ({ userId, name, avatarConfig }) => {
    AllUsers.push({ userId: userId, name: name, avatarConfig: avatarConfig });
    store.dispatch(UPDATE_USER_COUNT(AllUsers.length));

    window.enqueueSnackbar(`${name} Joined !`, {
      variant: "info",
      autoHideDuration: 2000,
    });

    spawnRemotePlayer(userId, name, scene, avatarConfig).then(
      (remotePlayer) => {
        socket.emit("pingCurrentPos", { room_ID: room_ID });
        // remotePlayer.position = new BABYLON.Vector3(-0.1, 1.4, 19.4);
      }
    );
  });

  socket.on("userleft", ({ userId }) => {
    try {
      scene.getMeshByName(userId).dispose();
      const arr = AllUsers.filter((e) => e.userId != userId);
      AllUsers = arr;
      store.dispatch(UPDATE_USER_COUNT(arr.length));
    } catch (err) {
      console.log("user does not exist");
    }
  });

  socket.on("rem-pos", ({ userId, pos, rot }) => {
    const mesh = scene.getMeshByName(userId);
    if (mesh) {
      mesh.position = new BABYLON.Vector3(pos.x, pos.y - 1.2, pos.z);
      mesh.rotation = new BABYLON.Vector3(
        mesh.rotation.x,
        rot,
        mesh.rotation.z
      );
      mesh.animations[6].play(false);
    }
  });

  let retries = 20;

  socket.io.on("error", (e) => {
    if (e.message == "websocket error" && retries == 0) {
      session_Ended(
        store.getState().UserReducer.userData.UUID,
        store.getState().UserReducer.userData.Room_Id
      ).then(() => {
        window.location.href = landingUrl;
      });
    }
    retries--;
  });
};

export const switchJersey = (jerseyUrl) => {
  socket.emit("jerseyChange", {
    userId: store.getState().UserReducer.userData.UUID,
    room: store.getState().UserReducer.userData.Room_Id,
    jerseyUrl: jerseyUrl,
  });
};

export const onAnimPlayed = (animName) => {
  if (store.getState().UserReducer.zoneData !== "FanZone") {
    socket.emit("emote", {
      userId: store.getState().UserReducer.userData.UUID,
      room: store.getState().UserReducer.userData.Room_Id,
      animName: animName,
    });
  }
  socket.emit("emote", {
    userId: store.getState().UserReducer.userData.UUID,
    room: "FanZone",
    animName: animName,
  });
};

export const localUserLeave = async (userID, roomID, scene) => {
  socket.removeAllListeners();
  socket.disconnect();
  socket.emit("leaveroom");
  session_Ended(userID, roomID);

  //delete all players
  if (AllUsers !== undefined) {
    await Promise.all([
      AllUsers.forEach((user, i) => {
        if (user.userId != store.getState().UserReducer.userData.UUID) {
          scene.getMeshByName(user.userId)?.dispose();
        }
        AllUsers.splice(i, 1);
      }),
    ]);
  }
  resetAllUsers();
};

const resetAllUsers = () => {
  AllUsers = [];
};

export const addAgoraVideo = (UID, ms) => {
  const uid = UID.split("_")[0];
  let videoPlane;

  if (currentScene == null) {
    return;
  }
  const mesh = currentScene.getMeshByName(uid);
  if (ms != undefined && mesh != undefined) {
    mesh.getChildMeshes().forEach((mesh) => {
      if (mesh.name === "videoPlane") {
        videoPlane = mesh;
      }
    });

    if (!mesh.metadata.videoAdded) {
      mesh.metadata.videoAdded = true;
      const videoTexture = new BABYLON.VideoTexture(
        "VT" + uid,
        ms?._player?.videoElement,
        currentScene,
        true,
        true,
        BABYLON.VideoTexture.TRILINEAR_SAMPLINGMODE,
        { loop: true, autoPlay: true, autoUpdateTexture: true }
      );
      videoPlane.material.emissiveTexture = videoTexture;
    } else {
      videoPlane.material.emissiveTexture.video.srcObject =
        ms?._player?.videoElement.srcObject;
    }
    videoPlane.material.emissiveTexture.video.onloadedmetadata = () => {
      videoPlane.material.emissiveTexture.video.play();
    };
    videoPlane.setEnabled(true);
  }
};

export const disableVideo = (UID) => {
  const uid = UID.split("_")[0];
  if (currentScene == null) {
    return;
  }
  const mesh = currentScene.getMeshByName(uid);
  if (mesh) {
    mesh.getChildMeshes().forEach((mesh) => {
      if (mesh.name === "videoPlane") {
        mesh.setEnabled(false);
      }
    });
  }
};
