import React, { useState } from "react";
import videoLogo from "../../assets/videoIcon.png";
import emojiLogo from "../../assets/Emoji.png";
import MessageLogo from "../../assets/Message.png";
import Mic from "../../assets/Mic.png";
import Exit from "../../assets/Exit.png";
import videoOff from "../../assets/videoOff.png";
import micOff from "../../assets/micOff.png";
import shareLogo from "../../assets/shareIcon.png";
import emojiselectedLogo from "../../assets/emojiSelected.png";
import camera from "../../assets/camera.png";
import zone from "../../assets/zone.png";
import zoneSelect from "../../assets/zoneSelect.png";
import messageSelect from "../../assets/messageSelect.png";

import {
  toggleAudio,
  toggleVideo,
} from "../../../../../Agora/AgoraVideoService/agoraVideoService";
import "./Bar.css";
import { List, ListItem, ListItemButton } from "@mui/material";
import Share from "../Share/Share";
import selectShare from "../../assets/shareSelect.png";
import { FanZoneName, landingUrl } from "../../../../../constants/constants";
import { useSelector } from "react-redux";
import { store } from "../../../../../redux/redux";

const reactions = [
  {
    name: "Victory",
    anim: "Victory Idle",
  },
  {
    name: "Dance",
    anim: "Dancing Twerk",
  },
  {
    name: "Hiphop",
    anim: "Hip Hop Dancing",
  },
  {
    name: "Jumping jacks",
    anim: "Jumping Jacks",
  },
  {
    name: "Reaction",
    anim: "Reacting",
  },
  {
    name: "Clap",
    anim: "Standing Clap",
  },
  {
    name: "Taunt",
    anim: "Taunt Gesture",
  },
];

function Bar({
  audioMuted,
  videoMuted,
  setopenChat,
  openChat,
  setcontent,
  setwidth,
  handleClick,
  setisBottom,
  target,
  setmodalContent,
  setopenModal,
  setmodalWidth,
  modalOpen,
  startAnim,
  switchZone,
  setupStadium,
  setupLounge,
  setupFanZone,
  takeSelfie,
  setisiframe,
  setdisplayMessages,
  shareOpen,
  setshareOpen,
}) {
  const Zone = useSelector((state) => state.UserReducer.zoneData);
  const userData = useSelector((state) => state.UserReducer.userData);

  const Emoji = () => {
    return (
      <List style={{ padding: 0 }}>
        {reactions.map((e) => (
          <ListItem
            button
            onClick={() => {
              startAnim(e.anim);
            }}
          >
            {e.name}
          </ListItem>
        ))}
      </List>
    );
  };

  const Zones = () => {
    return (
      <List style={{ padding: 0 }}>
        <ListItem
          button
          onClick={(e) => {
            // switchZone("Fan Zone", setdisplayMessages);
            setupFanZone(setdisplayMessages);
          }}
        >
          {"Meet & Greet LSG Players"}
        </ListItem>
        <ListItem
          button
          onClick={(e) => {
            // switchZone("Experience Lounge", setdisplayMessages);
            setupLounge(setdisplayMessages);
          }}
        >
          Enter Experience Lounge
        </ListItem>
        <ListItem
          button
          onClick={(e) => {
            setupStadium(setdisplayMessages);
          }}
        >
          Enter Stadium
        </ListItem>

        {store.getState().UserReducer.leaderBoard?.UserData.totalScore ==
          999999999 && (
          <ListItemButton
            button
            onClick={() => {
              const userid = store.getState().UserReducer.userData.UUID;
              setmodalWidth("lg");
              setmodalContent(`https://pc-building-game.web.app?uid=${userid}`);
              setopenModal(true);
              setisiframe(true);
            }}
          >
            PC Building Competition
          </ListItemButton>
        )}
      </List>
    );
  };
  return (
    <div>
      {Zone == FanZoneName && (
        <div className="left_container">
          <button
            disabled={userData.Role == "Winner"}
            className="exp_BTN"
            onClick={() => {
              toggleVideo();
            }}
            style={{
              backgroundColor: videoMuted ? "white" : "#0156E1",
            }}
          >
            {
              <img
                style={{ width: "18px" }}
                src={videoMuted ? videoOff : videoLogo}
              />
            }
          </button>

          <button
            disabled={userData.Role == "Winner"}
            className="exp_BTN"
            style={{
              backgroundColor: audioMuted ? "white" : "#0156E1",
            }}
            onClick={() => {
              toggleAudio();
            }}
          >
            {<img style={{ width: "18px" }} src={audioMuted ? micOff : Mic} />}
          </button>
        </div>
      )}
      <div className="right_container">
        <button
          id="Zone"
          style={{ backgroundColor: target == "Zone" ? "#0156E1" : "white" }}
          className="exp_BTN blob"
          onClick={(e) => {
            handleClick(e);
            setcontent(Zones);
            setwidth("12em");
            setisBottom(true);
          }}
        >
          {
            <img
              id="Zone"
              style={{ width: "18px" }}
              src={target == "Zone" ? zoneSelect : zone}
            />
          }
        </button>
        <button
          className="exp_BTN"
          id="mobiledisable"
          onClick={() => {
            takeSelfie();
          }}
        >
          {<img style={{ width: "18px" }} src={camera} />}
        </button>
        <button
          id="Emoji"
          style={{ backgroundColor: target == "Emoji" ? "#0156E1" : "white" }}
          className="exp_BTN"
          onClick={(e) => {
            handleClick(e);
            setcontent(Emoji);
            setwidth("8em");
            setisBottom(true);
          }}
        >
          {
            <img
              id="Emoji"
              style={{ width: "18px" }}
              src={target == "Emoji" ? emojiselectedLogo : emojiLogo}
            />
          }
        </button>
        <button
          id="mobiledisable"
          className="exp_BTN"
          onClick={() => {
            setopenChat(!openChat);
          }}
          style={{ backgroundColor: openChat ? "#0156E1" : "white" }}
        >
          {
            <img
              style={{ width: "18px" }}
              src={openChat ? messageSelect : MessageLogo}
            />
          }
        </button>
        <button
          id="mobiledisable"
          className="exp_BTN"
          style={{
            backgroundColor: modalOpen && shareOpen ? "#0156E1" : "white",
          }}
          onClick={() => {
            setmodalWidth("xs");
            setisiframe(false);
            setmodalContent(
              <Share
                onClose={() => {
                  setopenModal(false);
                  setshareOpen(false);
                }}
              />
            );
            setopenModal(true);
            setshareOpen(true);
          }}
        >
          {
            <img
              style={{ width: "18px" }}
              src={modalOpen && shareOpen ? selectShare : shareLogo}
            />
          }
        </button>
        <button
          className="exp_BTN"
          onClick={() => {
            window.location.href = landingUrl;
          }}
          style={{ backgroundColor: "red" }}
        >
          {<img style={{ width: "18px" }} src={Exit} />}
        </button>
      </div>
    </div>
  );
}

export default Bar;
