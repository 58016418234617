import React from "react";
import Menu from "@mui/material/Menu";

function MenuComponent({
  anchorEl,
  Content,
  width,
  handleClose,
  isBottom = true,
}) {
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: isBottom ? -1.5 : 1.5,
          width: { width },
          borderRadius: "0.5em",
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: !isBottom ? -10 : "unset",
            right: 14,
            bottom: isBottom ? 0 : "none",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{
        horizontal: "right",
        vertical: isBottom ? "bottom" : "top",
      }}
      anchorOrigin={{
        horizontal: "right",
        vertical: isBottom ? "top" : "bottom",
      }}
    >
      {Content}
    </Menu>
  );
}

export default MenuComponent;
