import * as BABYLON from "babylonjs";
import * as MATERIAL from "babylonjs-materials";
export function addWaterShader(scene) {
  const waterBody = scene.getMeshByName("Reception_Area_Water");
  var water = new MATERIAL.WaterMaterial(
    "water",
    scene,
    new BABYLON.Vector2(1024, 1024)
  );
  water.backFaceCulling = true;
  water.bumpTexture = new BABYLON.Texture(
    "https://www.babylonjs-playground.com/textures/waterbump.png",
    scene
  );
  water.windForce = -5;
  water.waveHeight = 0.2;
  water.bumpHeight = 0.5;
  water.waveLength = 0.5;
  water.colorBlendFactor = 0.1;
  water.waterColor = new BABYLON.Color3.FromHexString(
    "#3381ff"
  ).toLinearSpace();

  water.specularPower = new BABYLON.Color3.FromHexString(
    "#3381ff"
  ).toLinearSpace();
  water.addToRenderList(scene.getMeshByName("hdrSkyBox"));

  waterBody.material = water;
}
