import React from "react";
import "./Footer.css";
import footerLogo from "../Assets/footerLogo.png";
import dicordLogo from "../Assets/discordLogo.png";
import instaLogo from "../Assets/instaLogo.png";
import twitterLogo from "../Assets/twitterLogo.png";
import youtubeLogo from "../Assets/youtubeLogo.png";
import linkedinLogo from "../Assets/linkedinLogo.png";
import sendIcon from "../Assets/sendIcon.png";

function FooterComponent() {
  return (
    <div className="footer_root">
      <text>Copyright © 2022 MetaQube.ai</text>
    </div>
  );
}

export default FooterComponent;
