import AgoraRTC from "agora-rtc-sdk-ng";
import { getVideoToken } from "../../API/API";
import {
  addAgoraVideo,
  disableVideo,
} from "../../Container/Experience/Network";
import {
  REMOTE_USER,
  UPDATE_LOCAL_AGORA_DATA,
  REMOTE_USER_LEFT,
  RESET_VIDEO_REDUCER,
} from "../../redux/actions/agoraVideoAction";
import { store } from "../../redux/redux";

let localTracks = { audioTrack: "", videoTrack: "" };
const options = {
  APP_ID: null,
  TOKEN: null,
  CHANNEL: null,
  uid: null,
  container: null,
};
const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

AgoraRTC.setLogLevel(3);
const handleErr = (err) => {
  console.log(err);
};

let { APP_ID, TOKEN, CHANNEL, uid, container } = options;

export const joinCall = async (channel) => {
  container = document.getElementById("container");
  const userData = store.getState().UserReducer.userData;
  const fn = userData.FirstName.replaceAll(" ", "");
  uid = userData.UUID + "_" + fn;
  CHANNEL = channel;
  // uid = (Math.random(0, 1) * 1000).toString();
  // CHANNEL = "Hell";
  await getVideoToken(uid, CHANNEL).then((data) => {
    APP_ID = data.app_id;
    TOKEN = data.token;
  });
  await client.join(APP_ID, CHANNEL, TOKEN, uid).catch(handleErr);
  registerListeners();
  await initialiseAudio();
  await initialiseVideo();
};

const registerListeners = () => {
  AgoraRTC.onMicrophoneChanged = (info) => {
    AgoraRTC.getMicrophones().then((e) => {
      localTracks.audioTrack
        .setDevice(e[0].deviceId)
        .then(() => {
          console.log("set device success");
        })
        .catch((e) => {
          console.log("set device error", e);
        });
    });
  };
  client.on("user-published", handleUserjoined);
  client.on("user-left", handleUserLeft);
  client.on("user-unpublished", handleUnpublished);
};

const initialiseAudio = async () => {
  try {
    localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
      AEC: true,
      ANS: true,
    });
    store.dispatch(
      UPDATE_LOCAL_AGORA_DATA({
        audioMuted: false,
        audioTrack: localTracks.audioTrack,
        MicPermission: true,
      })
    );
    client.publish(localTracks.audioTrack).then(async () => {
      await toggleAudio();
    });
  } catch {
    store.dispatch(
      UPDATE_LOCAL_AGORA_DATA({
        audioMuted: true,
        audioTrack: undefined,
        MicPermission: false,
      })
    );
  }
};

const initialiseVideo = async () => {
  try {
    localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack();
    // localTracks.videoTrack.play(document.getElementById("video"));
    const temp = document.createElement("div");
    temp.id = uid;
    container.append(temp);
    localTracks.videoTrack?.play(temp);
    store.dispatch(
      UPDATE_LOCAL_AGORA_DATA({
        videoMuted: false,
        videoTrack: localTracks.videoTrack,
        CamPermission: true,
      })
    );

    client.publish(localTracks.videoTrack).then(async () => {
      toggleVideo();
    });
  } catch (e) {
    console.log(e);
    store.dispatch(
      UPDATE_LOCAL_AGORA_DATA({
        videoMuted: true,
        videoTrack: undefined,
        CamPermission: false,
      })
    );
  }
};

const handleUserjoined = async (user, mediatype) => {
  await client.subscribe(user, mediatype);
  if (mediatype == "video") {
    const temp = document.createElement("div");
    temp.id = user.uid;
    container.append(temp);
    user?.videoTrack?.play(temp);
    store.dispatch(
      REMOTE_USER({
        userID: user.uid,
        videoTrack: user.videoTrack,
        videoMuted: false,
      })
    );

    addAgoraVideo(user.uid, user?.videoTrack);
  } else if (mediatype == "audio") {
    user.audioTrack.play();
    store.dispatch(
      REMOTE_USER({
        userID: user.uid,
        audioTrack: user.audioTrack,
        audioMuted: false,
      })
    );
  }
};

const handleUnpublished = (user, mediaType) => {
  if (mediaType == "video") {
    document.getElementById(user.uid)?.remove();
    store.dispatch(
      REMOTE_USER({
        userID: user.uid,
        videoMuted: true,
      })
    );

    disableVideo(user.uid);
  } else {
    store.dispatch(
      REMOTE_USER({
        userID: user.uid,
        audioMuted: true,
      })
    );
  }
};

const handleUserLeft = (user) => {
  store.dispatch(REMOTE_USER_LEFT({ id: user.uid }));
};

const toggleAudio = async () => {
  if (store.getState().VideoReducer.localAgoraData.MicPermission) {
    if (localTracks.audioTrack.enabled) {
      await localTracks.audioTrack.setEnabled(false);
    } else {
      await localTracks.audioTrack.setEnabled(true);
    }
    store.dispatch(
      UPDATE_LOCAL_AGORA_DATA({
        audioMuted: !localTracks.audioTrack.enabled,
      })
    );
  }
};

const toggleVideo = async () => {
  if (store.getState().VideoReducer.localAgoraData.CamPermission) {
    if (localTracks.videoTrack.enabled) {
      await localTracks.videoTrack.setEnabled(false);
      disableVideo(uid);
    } else {
      await localTracks.videoTrack.setEnabled(true);
      addAgoraVideo(uid, localTracks.videoTrack);
    }
    store.dispatch(
      UPDATE_LOCAL_AGORA_DATA({
        videoMuted: !localTracks.videoTrack.enabled,
      })
    );
  }
};

const leaveCall = async () => {
  store.dispatch(RESET_VIDEO_REDUCER());
  if (localTracks.videoTrack && localTracks.videoTrack.enabled) {
    await localTracks.videoTrack.setEnabled(false);
  }
  if (localTracks.audioTrack && localTracks.audioTrack.enabled) {
    await localTracks.audioTrack.setEnabled(false);
  }
  await client.unpublish(localTracks.videoTrack);
  await client.unpublish(localTracks.audioTrack);
  await client.leave();
};

export { toggleAudio, toggleVideo, leaveCall };
