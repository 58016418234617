import { Avatar, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./ParticipantPanel.css";
import { useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  MicOffOutlined,
  MicOutlined,
  VideocamOffOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import { sendActionMessage } from "../../../../../../Agora/AgoraChatService/agoraChat";
import {
  toggleAudio,
  toggleVideo,
} from "../../../../../../Agora/AgoraVideoService/agoraVideoService";
import { FanZoneName } from "../../../../../../constants/constants";

function ParticipantPanel() {
  const messageReducer = useSelector(
    (state) => state.MessageReducer.Messages.Users
  );
  const Zone = useSelector((state) => state.UserReducer.zoneData);
  const RemotevideoReducer = useSelector(
    (state) => state.VideoReducer.remoteUsers
  );
  const localagoraData = useSelector(
    (state) => state.VideoReducer.localAgoraData
  );
  const userReducer = useSelector((state) => state.UserReducer.userData);
  const role = userReducer.Role;
  const userName = userReducer.UUID + "_" + userReducer.FirstName;

  useEffect(() => {}, [messageReducer]);

  return (
    <div className="container_participant_root">
      {Object.keys(messageReducer).map((e) => (
        <>
          <div className="participant_container">
            <div className="name_Container">
              <Avatar>{e.split("_")[1][0]}</Avatar>
              <text>{e.split("_")[1]}</text>
            </div>
            {Zone == "Fan Zone" && (
              <div className="controls_container">
                <IconButton
                  disabled={role != "Admin" || Zone != FanZoneName}
                  onClick={() => {
                    if (e != userName) {
                      sendActionMessage({
                        type: "CAMERA_OFF_USER",
                        id: e,
                        videoMuted: false,
                      });
                    } else {
                      toggleVideo();
                    }
                  }}
                >
                  {Zone == FanZoneName ? (
                    e != userName ? (
                      RemotevideoReducer[e]?.videoMuted != undefined ? (
                        RemotevideoReducer[e]?.videoMuted ? (
                          <VideocamOffOutlined style={{ color: "red" }} />
                        ) : (
                          <VideocamOutlined style={{ color: "grey" }} />
                        )
                      ) : (
                        <VideocamOffOutlined style={{ color: "red" }} />
                      )
                    ) : localagoraData.videoMuted ? (
                      <VideocamOffOutlined style={{ color: "red" }} />
                    ) : (
                      <VideocamOutlined style={{ color: "grey" }} />
                    )
                  ) : (
                    <VideocamOffOutlined style={{ color: "red" }} />
                  )}
                </IconButton>
                <IconButton
                  disabled={role != "Admin" || Zone != FanZoneName}
                  onClick={() => {
                    if (e != userName) {
                      sendActionMessage({
                        type: "MUTE_USER",
                        id: e,
                        audioMuted: false,
                      });
                    } else {
                      toggleAudio();
                    }
                  }}
                >
                  {Zone == FanZoneName ? (
                    e != userName ? (
                      RemotevideoReducer[e]?.audioMuted != undefined ? (
                        RemotevideoReducer[e]?.audioMuted ? (
                          <MicOffOutlined style={{ color: "red" }} />
                        ) : (
                          <MicOutlined style={{ color: "grey" }} />
                        )
                      ) : (
                        <MicOffOutlined style={{ color: "red" }} />
                      )
                    ) : localagoraData.audioMuted ? (
                      <MicOffOutlined style={{ color: "red" }} />
                    ) : (
                      <MicOutlined style={{ color: "grey" }} />
                    )
                  ) : (
                    <MicOffOutlined style={{ color: "red" }} />
                  )}
                </IconButton>
                {e != userName && Zone == FanZoneName && role == "Admin" && (
                  <IconButton
                    onClick={() => {
                      sendActionMessage({
                        type: "KICK_USER",
                        id: e,
                      });
                    }}
                  >
                    <LogoutIcon style={{ color: "grey" }} />
                  </IconButton>
                )}
              </div>
            )}
          </div>
        </>
      ))}
    </div>
  );
}

export default ParticipantPanel;
