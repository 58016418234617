import React from "react";
import "./LoadingScreen.css";
import Logo from "../assets/metaqubeLogo.png";

function LoadingScreen({ firstTime, loadingPercent }) {
  return (
    <div className="loading_root">
      <div className="inner">
        <img src={Logo} />
      </div>
      {firstTime && <text id="t-1">{loadingPercent}%</text>}
      <text id="t-2">Preparing Your Environment</text>
    </div>
  );
}

export default LoadingScreen;
