import CustomRoutes from "./Routes/Routes";
import { SnackbarProvider } from "notistack";
function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      hideIconVariant={true}
      style={{
        backgroundColor: "rgba(1, 48, 114,0.8)",
        backdropFilter: "saturate(180%) blur(2px)",
      }}
    >
      <>
        <CustomRoutes />
      </>
    </SnackbarProvider>
  );
}

export default App;
