import React, { useState } from "react";
import "./Section2.css";
import playerStats from "../Assets/PlayerStats.png";
import fanzone from "../Assets/FanZone.png";
import PEL from "../Assets/PEL.png";
import win from "../Assets/win.png";

function Section2() {
  const [selected, setselected] = useState(0);
  const [imgsrc, setimgsrc] = useState(playerStats);
  return (
    <div className="root container">
      <header>What to Expect/Features</header>
      <div className="stats-container">
        <div className="img_Holder">
          <img src={imgsrc} />
        </div>
        <div className="statsHolder">
          <div
            className={"data-container"}
            onClick={() => {
              setselected(0);
              setimgsrc(playerStats);
            }}
            style={{ background: selected == 0 ? "#323232" : "none" }}
          >
            <h1>Experience layout</h1>

            <h4 className={selected == 0 ? "increaseHeight" : "decreaseHeight"}>
              A circle of panels highlighting different players lines the
              stadium space. Visitors can get information on detailed player
              stats and virtually try on official player jerseys for an added
              unique experience.
            </h4>
          </div>
          <div
            className="data-container"
            onClick={() => {
              setselected(1);
              setimgsrc(fanzone);
            }}
            style={{ background: selected == 1 ? "#323232" : "none" }}
          >
            <h1>Fan Zone</h1>
            <h4 className={selected == 1 ? "increaseHeight" : "decreaseHeight"}>
              We’ve created a special, highly exclusive interactive zone for
              fans of all things IPL, where visitors and fans can interact with
              IPL team players virtually and form brand new metaverse
              communities in a brand new setting. This place will only be
              accessible to a select few visitors.
            </h4>
          </div>
          <div
            className="data-container"
            onClick={() => {
              setselected(2);
              setimgsrc(PEL);
            }}
            style={{ background: selected == 2 ? "#323232" : "none" }}
          >
            <h1>Product experience Lounge</h1>
            <h4 className={selected == 2 ? "increaseHeight" : "decreaseHeight"}>
              Our product experience lounge will give visitors an exclusive
              glimpse of four cutting edge Gigabyte products. Our AORUS and AERO
              laptops and AORUS Monitors. We’re excited for users to interact
              with our products virtually and participate in our culture of
              innovation.
            </h4>
          </div>
          <div
            className="data-container"
            onClick={() => {
              setselected(3);
              setimgsrc(win);
            }}
            style={{ background: selected == 3 ? "#323232" : "none" }}
          >
            <h1>Chance to win a PC</h1>
            <h4 className={selected == 3 ? "increaseHeight" : "decreaseHeight"}>
              As an added bonus, we’ve designed an ultra-addictive PC Build game
              where visitors must assemble a Gigabyte product as quickly as
              possible. And there’s more - whoever assembles the virtual PC the
              fastest will win an real-life Gigabyte PC console.
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
