import EmojiPicker from "emoji-picker-react";
import React, { useState } from "react";
import { sendMessage } from "../../../../../../Agora/AgoraChatService/agoraChat";
import sendBtn from "../../../assets/send.png";
import emojiBtn from "../../../assets/smile.png";

function ChatInput({ message, setmessage }) {
  const [emojiEnabled, setEmojiEnabled] = useState(false);
  return (
    <>
      <div className="Chatinput_container">
        {emojiEnabled && (
          <EmojiPicker
            onEmojiClick={(e, eo) => {
              setmessage(message + eo.emoji);
            }}
            pickerStyle={{ width: "100%" }}
          />
        )}
        <div className="chat_input_holder">
          <input
            className="chat_input"
            value={message}
            onChange={(e) => {
              setmessage(e.target.value);
            }}
            placeholder="Message..."
          />
          <button
            onClick={() => {
              setEmojiEnabled(!emojiEnabled);
            }}
          >
            {" "}
            {<img style={{ width: "100%" }} src={emojiBtn} />}
          </button>
          <button
            disabled={message == ""}
            onClick={() => {
              sendMessage(message);
              setmessage("");
              setEmojiEnabled(false);
            }}
          >
            {<img style={{ width: "100%" }} src={sendBtn} />}
          </button>
        </div>
      </div>
    </>
  );
}

export default ChatInput;
