import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { sendOTP } from "../../../API/API";
import { SUCCESS_CODE } from "../../../constants/constants";
import "./Login.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

function Login({ setnumber, setotpsent, number, setloading, loading }) {
  const [error, seterror] = useState("");
  return (
    <>
      <header>Enter your mobile number</header>
      <text>
        <span>We‘ll send you a</span>
        <span style={{ color: "black", fontWeight: "bold" }}>
          {" "}
          6 digit Code
        </span>
        <span> on your mobile number for verification</span>
      </text>
      <div>
        <PhoneInput
          country={"in"}
          onChange={(e) => {
            setnumber("+" + e);
          }}
          inputClass="input_class"
        />
      </div>
      {error != "" && <text style={{ color: "#CB2626" }}>*{error} </text>}
      <button
        disabled={number == "" || loading}
        className="BTN custom-btn"
        onClick={() => {
          setloading(true);
          sendOTP(number).then((e) => {
            if (e.responseCode == SUCCESS_CODE) {
              setotpsent(true);
            } else {
              seterror(e.message);
            }
            setloading(false);
          });
        }}
      >
        {loading ? (
          <CircularProgress size={18} style={{ color: "white" }} />
        ) : (
          "Send OTP"
        )}
      </button>
    </>
  );
}

export default Login;
