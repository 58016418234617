import React, { useEffect, useRef, useState } from "react";
import Circle from "react-color/lib/components/circle/Circle";
import "./Avatar.css";

import AvatarCustomiser from "../Experience/AvatarCustomiser";
import { store } from "../../redux/redux";
import { AVATAR_DATA } from "../../redux/actions/avatarAction";
import { useNavigate } from "react-router-dom";

import Gender from "./assets/Gender.png";
import Glasses from "./assets/Glasses.png";
import Hair from "./assets/Hair.png";
import Moustache from "./assets/Moustache.png";
import Trousers from "./assets/Trousers.png";
import Tshirt from "./assets/Tshirt.png";
import rightBTN from "./assets/rightBTN.png";
import lefttBTN from "./assets/leftBTN.png";
import selectGlasses from "./assets/selectGlasses.png";
import selectHair from "./assets/selectHair.png";
import selectMoustache from "./assets/selectMoustache.png";
import selectTrousers from "./assets/selectTrousers.png";
import selectTshirt from "./assets/selectTshirt.png";
import male from "./assets/Male.png";
import selectMale from "./assets/selectMale.png";
import female from "./assets/Girl.png";
import selectfemale from "./assets/selectGirl.png";
import { Male } from "@mui/icons-material";
import { redirecttoLandingPage } from "../../constants/constants";

let indexes = {
  Gender: 0,
  Beard: 0,
  Eyeglass: 0,
  Hair: 0,
  Top: 1,
  Bottom: 0,
  skinColor: "#B87352",
};

const resetIndexes = () => {
  indexes = { ...indexes, Beard: 0, Eyeglass: 0, Hair: 0, Top: 1, Bottom: 0 };
};
export default function Avatar({ auth }) {
  const [data, setdata] = useState();
  const [selectedProperty, setselectedProperty] = useState("");
  const ref = useRef();
  const navigate = useNavigate();
  const [genderIndex, setGenderIndex] = useState(0);
  useEffect(() => {
    redirecttoLandingPage(auth);
    fetch("/avatarData.json", {})
      .then((res) => res.json())
      .then((data) => {
        setdata(data);
      });
  }, []);

  const stopBabylonRenderLoop = () => {
    ref.current.stopBabylonRenderLoop();
  };
  const switchProps = (name, index) => {
    ref.current.switchProps(name, index);
  };

  const changeColor = (color) => {
    ref.current.changeSkinColor(color);
  };
  const onNext_Click = () => {
    let index = indexes[selectedProperty];

    if (selectedProperty == "") {
      return;
    }

    const selectedGender = Object.keys(data["Gender"])[indexes["Gender"]];
    const arr = data["Gender"][selectedGender][selectedProperty];

    index++;
    if (index > arr) {
      index = 0;
    }
    indexes[selectedProperty] = index;

    switchProps(selectedProperty, index);
  };
  const onPrev_Click = () => {
    let index = indexes[selectedProperty];
    if (selectedProperty == "") {
      return;
    }

    const selectedGender = Object.keys(data["Gender"])[indexes["Gender"]];
    const arr = data["Gender"][selectedGender][selectedProperty];

    index--;
    if (index < 0) {
      index = arr;
    }
    indexes[selectedProperty] = index;

    switchProps(selectedProperty, index);
  };
  return (
    <>
      <div className="Avatar_container">
        <div className="Avatar_Box">
          <div className="customiser">
            <header>CUSTOMIZE YOUR AVATAR</header>
            <div className="Render_Container">
              {selectedProperty != "Gender" && selectedProperty != "" && (
                <div style={{ display: "flex" }}>
                  <button className="change_btn" onClick={onPrev_Click}>
                    <img src={lefttBTN} />
                  </button>
                </div>
              )}
              <AvatarCustomiser ref={ref} />
              {selectedProperty != "Gender" && selectedProperty != "" && (
                <div style={{ display: "flex" }}>
                  <button className="change_btn" onClick={onNext_Click}>
                    <img src={rightBTN} />
                  </button>
                </div>
              )}
            </div>
            <Circle
              className="color-picker"
              colors={["#8d5524", "	#c68642", "#e0ac69", "#f1c27d", "#ffdbac"]}
              onChange={(e) => {
                indexes["skinColor"] = e.hex;
                changeColor(e.hex);
              }}
            />
            <div className="property_Holder">
              <button
                onClick={() => {
                  setselectedProperty("Gender");
                  let temp = indexes.Gender;

                  const length = Object.keys(data["Gender"]).length;
                  if (indexes.Gender < length - 1) {
                    temp += 1;
                    indexes.Gender = temp;
                  } else {
                    temp -= 1;
                    indexes.Gender = temp;
                  }
                  setGenderIndex(temp);
                  resetIndexes();
                  switchProps("Gender", temp);
                }}
              >
                <img
                  src={
                    selectedProperty == "Gender"
                      ? genderIndex == 0
                        ? selectMale
                        : selectfemale
                      : genderIndex == 0
                      ? male
                      : female
                  }
                />
              </button>
              <button
                onClick={() => {
                  setselectedProperty("Eyeglass");
                }}
              >
                <img
                  src={selectedProperty == "Eyeglass" ? selectGlasses : Glasses}
                />
              </button>
              <button
                onClick={() => {
                  setselectedProperty("Hair");
                }}
              >
                <img src={selectedProperty == "Hair" ? selectHair : Hair} />
              </button>
              <button
                onClick={() => {
                  setselectedProperty("Beard");
                }}
              >
                <img
                  src={
                    selectedProperty == "Beard" ? selectMoustache : Moustache
                  }
                />
              </button>
              <button
                onClick={() => {
                  setselectedProperty("Top");
                }}
              >
                <img src={selectedProperty == "Top" ? selectTshirt : Tshirt} />
              </button>
              <button
                onClick={() => {
                  setselectedProperty("Bottom");
                }}
              >
                <img
                  src={selectedProperty == "Bottom" ? selectTrousers : Trousers}
                />
              </button>
            </div>
            <button
              className="BTN customiser-btn"
              onClick={() => {
                store.dispatch(AVATAR_DATA(indexes));
                stopBabylonRenderLoop();
                navigate("/experience");
              }}
            >
              Proceed
            </button>
            <img className="bottom_logo" />
          </div>
        </div>
      </div>
    </>
  );
}
