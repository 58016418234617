import React, { Component } from "react";
import * as BABYLON from "babylonjs";
import "babylonjs-loaders";

import BabylonScene from "./Scene";
import type { SceneEventArgs } from "./Scene";
import { SceneLoader } from "babylonjs";

type Props = {};

class AvatarCustomiser extends Component<Props, {}> {
  constructor(props) {
    super(props);

    this.canvas = "";
    this.engine = "";
    this.scene = "";
    this.camera = "";
    this.light = "";
    this.state = {};

    this.genders = [
      {
        root: "",
      },
      {
        root: "",
      },
    ];

    this.previousProps = {
      Gender: 0,
      Beard: 0,
      Eyeglass: 0,
      Hair: 0,
      Top: 1,
      Bottom: 0,
      skinColor: "#B87352",
    };
    this.Holder = "";
  }
  onSceneMount = (e: SceneEventArgs) => {
    const { canvas, scene, engine } = e;
    this.canvas = canvas;
    this.engine = engine;
    this.scene = scene;

    this.setupCamera();
    this.setupLights();
    this.loadModel();
    engine.hideLoadingUI();

    engine.runRenderLoop(() => {
      if (scene) {
        scene.render();
      }
    });
  };

  setupCamera = () => {
    this.camera = new BABYLON.FreeCamera(
      "Camera",
      BABYLON.Vector3.Zero(),
      this.scene
    );
    this.camera.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
  };

  setupLights = () => {
    const light = new BABYLON.HemisphericLight(
      "light1",
      new BABYLON.Vector3(0, 1, 0),
      this.scene
    );
    light.intensity = 0.7;
    this.scene.clearColor = BABYLON.Color3.White();
  };

  loadModel = () => {
    SceneLoader.ImportMesh(
      "",
      "/",
      "male.glb",
      this.scene,
      (mesh, particleSystems, skeleton, animationGroups) => {
        this.genders[0].root = mesh[0];
        mesh[0].position = new BABYLON.Vector3(-5, -1.5, 0);
        mesh[0].rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
        mesh[0].scaling = new BABYLON.Vector3(0.15, 0.15, -0.2);
        mesh[0].animations.push(...animationGroups);
        mesh[0].animations[0].play(true);
        const shadow = BABYLON.MeshBuilder.CreateDisc(
          "shadow",
          { radius: 7 },
          this.scene
        );
        shadow.parent = mesh[0];
        shadow.rotation = new BABYLON.Vector3(Math.PI / 2, Math.PI, 0);
        const shadowMat = new BABYLON.StandardMaterial("shadowMat", this.scene);
        shadow.material = shadowMat;
        this.Holder = mesh[0];
        this.resetCharacter();
      }
    );

    SceneLoader.ImportMesh(
      "",
      "/",
      "female.glb",
      this.scene,
      (mesh, particleSystems, skeleton, animationGroups) => {
        this.genders[1].root = mesh[0];

        mesh[0].setEnabled(false);

        mesh[0].position = new BABYLON.Vector3(-5, -1.5, 0);
        mesh[0].rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
        mesh[0].scaling = new BABYLON.Vector3(0.15, 0.15, -0.2);
        mesh[0].animations.push(...animationGroups);
        mesh[0].animations[0].play(true);
        const shadow = BABYLON.MeshBuilder.CreateDisc(
          "shadow",
          { radius: 7 },
          this.scene
        );
        shadow.parent = mesh[0];
        shadow.rotation = new BABYLON.Vector3(Math.PI / 2, Math.PI, 0);
        const shadowMat = new BABYLON.StandardMaterial("shadowMat", this.scene);
        shadow.material = shadowMat;
        // this.Holder = mesh[0];
        this.resetCharacter();
      }
    );
  };

  resetCharacter = () => {
    const meshes = this.Holder._children[0].getChildren();
    meshes.forEach((ch_mesh) => {
      switch (ch_mesh.name.split("_")[0]) {
        case "Hair":
          ch_mesh.setEnabled(false);
          if (ch_mesh.name.split("_")[1] == 0) {
            ch_mesh.setEnabled(true);
          }
          break;
        case "Top":
          ch_mesh.setEnabled(false);
          if (ch_mesh.name.split("_")[1] == 1) {
            ch_mesh.setEnabled(true);
          }
          break;
        case "Bottom":
          ch_mesh.setEnabled(false);
          if (ch_mesh.name.split("_")[1] == 0) {
            ch_mesh.setEnabled(true);
          }
          break;
        case "Eyeglass":
          ch_mesh.setEnabled(false);
          if (ch_mesh.name.split("_")[1] == 0) {
            ch_mesh.setEnabled(true);
          }
          break;
        case "Beard":
          ch_mesh.setEnabled(false);
          if (ch_mesh.name.split("_")[1] == 0) {
            ch_mesh.setEnabled(true);
          }
          break;
      }
    });
  };

  stopBabylonRenderLoop = () => {
    this.engine.stopRenderLoop();
  };

  switchProps = (propName, i) => {
    if (propName === "Gender") {
      this.genders[i].root.setEnabled(true);
      this.Holder.setEnabled(false);
      this.Holder = this.genders[i].root;
      this.resetCharacter();
      return;
    }
    this.Holder._children[0].getChildren().forEach((mesh) => {
      if (mesh.name == `${propName}_${this.previousProps[propName]}`) {
        mesh.setEnabled(false);
      }
    });
    this.Holder._children[0].getChildren().forEach((mesh) => {
      if (mesh.name === `${propName}_${i}`) {
        mesh.setEnabled(true);
        this.previousProps[propName] = i;
      }
    });
    // this.Holder.getChildMeshes().forEach((ch_mesh) => {
    //   if (ch_mesh.name == propName + `_${this.selectedProps[propName]}`) {
    //     ch_mesh.setEnabled(false);
    //   }
    // });

    // this.Holder._children[0].getChildren().forEach((mesh) => {
    //   if (mesh.name === `${propName}_${this.previousProps[propName]}`) {
    //     mesh.setEnabled(false);
    //     this.previousProps[propName] = i;
    //   }

    //   if (mesh.name === `${propName}_${i}`) {
    //     mesh.setEnabled(true);
    //   }
    // });

    // this.scene.getMeshByName(`${propName}_${this.selectedProps[propName]}`) ||
    //   this.scene.getTransformNodeByName(
    //     `${propName}_${this.selectedProps[propName]}`
    //   );
    // propMesh.setEnabled(false);
    // this.selectedProps[propName] = i;
    // propMesh =
    //   this.scene.getMeshByName(`${propName}_${i}`) ||
    //   this.scene.getTransformNodeByName(`${propName}_${i}`);
    // propMesh.setEnabled(true);
  };

  changeSkinColor = (color) => {
    this.Holder._children[0].getChildren().forEach((mesh) => {
      if (mesh.name === "body_New") {
        mesh.material.albedoColor =
          BABYLON.Color3.FromHexString(color).toLinearSpace();
      }
    });
  };

  render() {
    return <BabylonScene id="model_Render" onSceneMount={this.onSceneMount} />;
  }
}

export default AvatarCustomiser;
