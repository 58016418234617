import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Experience from "../Container/Experience/Experience";
import LandingPage from "../Container/LandingPage/LandingPage";
import LoginPage from "../Container/LoginPage/LoginPage";
import "../Components/Button/Button.css";
import Avatar from "../Container/Avatar/Avatar";
import UserDetails from "../Container/LoginPage/UserDetails/UserDetails";

function CustomRoutes() {
  const [auth, setauth] = useState(false);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/experience" element={<Experience auth={auth} />}></Route>
        <Route path="/login" element={<LoginPage setauth={setauth} />}></Route>
        <Route path="/avatar" element={<Avatar auth={auth} />}></Route>
        <Route path="/loading" element={<UserDetails />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default CustomRoutes;
