import React, { useState } from "react";
import Authenticate from "./Authenticate/Authenticate";
import Login from "./Login/Login";
import "./LoginPage.css";
import UserDetails from "./UserDetails/UserDetails";

function LoginPage({ setauth }) {
  const [otpsent, setotpsent] = useState(false);
  const [number, setnumber] = useState("");
  const [isRegistered, setisRegistered] = useState(true);
  const [loading, setloading] = useState(false);

  return (
    <div className="login_container">
      <div className="login_Box">
        <div className="login_data_container">
          {!otpsent && isRegistered && (
            <Login
              loading={loading}
              setloading={setloading}
              number={number}
              setnumber={setnumber}
              setotpsent={setotpsent}
            />
          )}
          {otpsent && isRegistered && (
            <>
              <Authenticate
                loading={loading}
                setauth={setauth}
                setloading={setloading}
                setisRegistered={setisRegistered}
                setotpsent={setotpsent}
                number={number}
              />
            </>
          )}
          {!isRegistered && (
            <UserDetails
              number={number}
              loading={loading}
              setloading={setloading}
            />
          )}
          <img className="bottom_logo" />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
