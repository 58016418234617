import React, { useEffect, useState } from "react";
import "./Drawer.css";
import ChatPanel from "./ChatPanel/ChatPanel";
import ParticipantPanel from "./ParticipantPanel/ParticipantPanel";
import ChatInput from "./ChatPanel/ChatInput";
import { useSelector } from "react-redux";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";

function Drawer({
  openChat,
  setopenchat,
  displayMessages,
  setdisplayMessages,
}) {
  const [message, setmessage] = useState("");
  const [selectedTab, setselectedTab] = useState(0);
  const messages = useSelector(
    (state) => state.MessageReducer.Messages.message
  );

  useEffect(() => {
    const temp = [...displayMessages];
    if (Object.keys(messages).length > 0) {
      Object.keys(messages).forEach((e) => {
        temp.push(messages[e]);
        setdisplayMessages(temp);
      });
    }
  }, [messages]);

  return (
    <div data-openchat={openChat} className="drawer">
      <div className="chat_container">
        <div style={{ position: "absolute" }}>
          <IconButton
            onClick={() => {
              setopenchat(false);
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
        <div className="Toggler_Container">
          <button
            data-selectedtab={selectedTab == 0 ? true : false}
            onClick={() => {
              setselectedTab(0);
            }}
          >
            Chat
          </button>
          <button
            data-selectedtab={selectedTab == 1 ? true : false}
            onClick={() => {
              setselectedTab(1);
            }}
          >
            Participants
          </button>
        </div>
        {selectedTab == 0 && (
          <div style={{ height: "100%" }}>
            <ChatPanel
              message={message}
              setmessage={setmessage}
              setchatopen={setopenchat}
              displayMessages={displayMessages}
            />
            <ChatInput message={message} setmessage={setmessage} />
          </div>
        )}
        {selectedTab == 1 && <ParticipantPanel />}
      </div>
    </div>
  );
}

export default Drawer;
