const avatarInitialState = {};

export const avatarReducer = (state = avatarInitialState, action) => {
  switch (action.type) {
    case "AVATAR_DATA": {
      const data = action.data;
      return data;
    }
    default: {
      return state;
    }
  }
};
