import { Dialog } from "@mui/material";
import React, { useEffect } from "react";
import "./DialogBox.css";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

function DialogBox({
  Content,
  open,
  backgroundColor,
  maxWidth,
  iframe,
  setopen,
}) {
  const userDataReducer = useSelector((state) => state.UserReducer.userData);
  return (
    <Dialog
      maxWidth={maxWidth ? maxWidth : "xs"}
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: backgroundColor
            ? backgroundColor
            : "rgba(235, 242, 250,0.8)",
          padding: "1rem",
          backdropFilter: "saturate(180%) blur(2px)",
        },
      }}
      open={open}
    >
      {iframe ? (
        <>
          <button
            className="closeBTN_dialog"
            onClick={() => {
              setopen(false);
            }}
          >
            <CloseIcon fontSize="small" style={{ color: "black" }} />
          </button>
          <iframe
            src={Content}
            frameBorder="0"
            allowFullScreen={true}
            className="iframe_class"
          ></iframe>
        </>
      ) : (
        <div className="Content-Container">{Content}</div>
      )}
    </Dialog>
  );
}

export default DialogBox;
