import React from "react";
import "./Tutorial.css";
import click from "./assets/click.png";
import clickDrag from "./assets/clickDrag.png";
import location from "./assets/location.png";
import keys from "./assets/keys.png";

function Tutorial({ onClose }) {
  return (
    <div className="tutorial_container">
      <header>Navigation</header>
      <div className="img_container">
        <div className="block_container">
          <img style={{ width: "80%" }} src={click} />
          <h3>Click</h3>
          <text>To move around</text>
        </div>
        <div className="block_container">
          <img style={{ width: "80%" }} src={clickDrag} />
          <h3>Click & Drag</h3>
          <text>To look around</text>
        </div>

        <div className="block_container">
          <img style={{ width: "80%" }} src={location} />
          <h3>Location</h3>
          <text>To go to location</text>
        </div>

        <div className="block_container" id="mobiledisable">
          <img style={{ width: "80%" }} src={keys} />
          <h3>WASD keys</h3>
          <text>To move around</text>
        </div>
      </div>
      <button
        className="BTN custom-btn"
        onClick={() => {
          onClose();
        }}
      >
        Proceed
      </button>
    </div>
  );
}

export default Tutorial;
