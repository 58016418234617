const INVALID_PARAM = "FAIL_01";
const MESSAGE_NOT_SENT = "FAIL_02";
export const SUCCESS_CODE = "OTP_SUCCESS_01";
export const AUTH_SUCCESS = "AUTH_SUCCESS_01";
const AUTH_FAIL = "AUTH_FAIL_01";
const OTP_EXPIRED = "OTP_EXPIRED_01";
export const CODE_NOT_REGISTERED = "WARNING_101";

export const FanZoneName = "Fan Zone";

export const landingUrl = "https://gigabyte-fanexperience.metaqube.ai/";
export const redirecttoLandingPage = (auth) => {
  if (!auth) {
    window.location.href = "/";
    return;
  }
};
