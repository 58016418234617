export const CHAT_USER_ADDED = (userData) => ({
  type: "CHAT_USER_ADDED",
  data: userData,
});
export const MESSAGE_RECIEVED = (userData) => ({
  type: "MESSAGE_RECIEVED",
  data: userData,
});
export const MESSAGE_SENT = (userData) => ({
  type: "MESSAGE_SENT",
  data: userData,
});
export const CHAT_USER_LEFT = (userData) => ({
  type: "CHAT_USER_LEFT",
  data: userData,
});
export const RESET_CHAT = (userData) => ({
  type: "RESET_CHAT",
  data: userData,
});
