export const getchatToken = async (uid) => {
  var myHeaders = new Headers();

  var raw = JSON.stringify({
    uid: uid,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const result = await fetch(
    "https://d5q2x0p901.execute-api.ap-south-1.amazonaws.com/default/GIGABYTE_AgoraRTMToken",
    requestOptions
  ).catch((error) => console.log("error", error));
  const jsonResult = await result.json();
  return jsonResult;
};

export const getVideoToken = async (uid, channelName) => {
  var myHeaders = new Headers();

  var raw = JSON.stringify({
    uid: uid,
    channelName: channelName,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const result = await fetch(
    "https://ld8kxn4ct2.execute-api.ap-south-1.amazonaws.com/default/GIGABYTE_AgoraRTCToken",
    requestOptions
  ).catch((error) => console.log("error", error));
  const jsonResult = await result.json();
  return jsonResult;
};

export const sendOTP = async (number) => {
  let headersList = {};

  let bodyContent = JSON.stringify({
    number: number,
  });

  const result = await fetch(
    "https://j0rb2hog58.execute-api.ap-south-1.amazonaws.com/default/SendOTP",
    {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    }
  );
  const jsonResult = await result.json();
  console.log(jsonResult);
  return jsonResult;
};

export const authenticateOTP = async (number, OTP) => {
  let headersList = {};

  let bodyContent = JSON.stringify({
    number: number,
    OTP: OTP,
  });

  const result = await fetch(
    "https://j0rb2hog58.execute-api.ap-south-1.amazonaws.com/default/GIGABYTE_Authentication",
    {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    }
  );
  const jsonResult = await result.json();
  return jsonResult;
};

export const updateData = async (firstname, lastname, email, city, number) => {
  let headersList = {};

  let bodyContent = JSON.stringify({
    number: number,
    FirstName: firstname,
    LastName: lastname,
    Email: email,
    City: city,
  });

  const result = await fetch(
    "https://j0rb2hog58.execute-api.ap-south-1.amazonaws.com/default/registrationform",
    {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    }
  );
  const jsonResult = await result.json();
  return jsonResult;
};

export const session_Ended = async (uuid, roomid) => {
  let headersList = {};
  let bodyContent = JSON.stringify({
    UUID: uuid,
    roomID: roomid,
  });

  const result = await fetch(
    "https://gexyw1ew03.execute-api.ap-south-1.amazonaws.com/default/SESSION_ENDED",
    {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    }
  );
  const jsonResult = await result.json();
  return jsonResult;
};
export const contact = async (email, name, message, subject) => {
  let headersList = {};

  let bodyContent = JSON.stringify({
    user_email: email,
    user_name: name,
    message: message,
    is_subscribed: true,
    contact_url: "http:adasdasd.com",
    project_id: "GB_001",
    subject: subject,
  });

  const result = await fetch(
    "https://l9qd9lzorl.execute-api.ap-south-1.amazonaws.com/contactFormAPI",
    {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    }
  );
  const jsonResult = await result.json();
  return jsonResult;
};
export const kickUser = async (uuid) => {
  let headersList = {};

  let bodyContent = JSON.stringify({
    UUID: uuid,
  });

  const result = await fetch(
    "https://gexyw1ew03.execute-api.ap-south-1.amazonaws.com/default/userKickOut",
    {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    }
  );
  const jsonResult = await result.json();
  return jsonResult;
};

export const getleaderBoard = async (uid) => {
  var myHeaders = new Headers();

  var raw = JSON.stringify({
    UUID: uid,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const result = await fetch(
    "https://j0rb2hog58.execute-api.ap-south-1.amazonaws.com/default/getLeaderboard",
    requestOptions
  ).catch((error) => console.log("error", error));
  const jsonResult = await result.json();
  return jsonResult;
};
