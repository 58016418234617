import * as BABYLON from "babylonjs";
import { store } from "../../redux/redux";

import {
  assetContainersHolder,
  loadExpLounge,
  loadStadium,
  temp,
} from "./Viewer";
import { applyJerseyToLocalPlayer, localPlayer } from "./Player";
import { switchJersey } from "./Network";

export const setUpButtons = (expZoneButton, scene) => {
  expZoneButton.actionManager = new BABYLON.ActionManager(scene);

  expZoneButton.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnPickTrigger,
      function (evt) {
        loadExpLounge();
      }
    )
  );
};

export const setupStadiumButton = (plane, scene) => {
  plane.actionManager = new BABYLON.ActionManager(scene);

  plane.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnPickTrigger,
      function (evt) {
        loadStadium();
      }
    )
  );
};

export const setVideo = (videoPlane, url, scene) => {
  const videoTex = new BABYLON.VideoTexture(
    "",
    url,
    scene,
    false,
    true,
    BABYLON.VideoTexture.TRILINEAR_SAMPLINGMODE,
    {
      loop: true,
      autoPlay: true,
      autoUpdateTexture: true,
      muted: true,
    }
  );

  let mesh = scene.getMeshByName(videoPlane);
  mesh.material = new BABYLON.PBRMaterial("", scene);
  mesh.material.albedoTexture = videoTex;
  mesh.material.unlit = true;
};

export const spawnJerseyButtons = async (scene) => {
  const btnHolder = new BABYLON.TransformNode("btnHolder", scene);
  // var hl = new BABYLON.HighlightLayer("hl1", scene);
  await fetch("/jerseyButtons.json").then((response) => {
    response.json().then((data) => {
      Object.keys(data).forEach((btn) => {
        const jerseyBTN = BABYLON.MeshBuilder.CreatePlane(
          btn,
          {
            size: 3,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          },
          scene
        );
        jerseyBTN.scaling = new BABYLON.Vector3(2, 0.5, 1);

        // hl.addMesh(jerseyBTN, BABYLON.Color3.Green());
        // hl.innerGlow = false;

        jerseyBTN.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;

        const jerseyMat = new BABYLON.StandardMaterial("jerseyMat", scene);
        jerseyMat.diffuseTexture = new BABYLON.Texture(
          "Group 13006.png",
          scene,
          false,
          true
        );
        jerseyMat.opacityTexture = jerseyMat.diffuseTexture;
        jerseyMat.emissiveColor = BABYLON.Color3.White();
        jerseyBTN.material = jerseyMat;

        jerseyBTN.position = new BABYLON.Vector3(
          data[btn].position.x,
          data[btn].position.y,
          data[btn].position.z
        );

        jerseyBTN.actionManager = new BABYLON.ActionManager(scene);
        jerseyBTN.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(
            BABYLON.ActionManager.OnPickTrigger,
            (e) => {
              applyJerseyToLocalPlayer(
                localPlayer,
                data[e.meshUnderPointer.name].url,
                scene
              );
              switchJersey(data[e.meshUnderPointer.name].url);
            }
          )
        );

        jerseyBTN.parent = btnHolder;
      });
    });
  });

  return btnHolder;
};

const switchZone = (zoneName) => {
  temp(zoneName, window.setdisplayMessages);
};
