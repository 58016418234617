import { Avatar } from "@mui/material";
import React from "react";
import "./ChatPanel.css";
import ScrollableFeed from "react-scrollable-feed";

function ChatPanel({ setchatopen, displayMessages }) {
  return (
    <>
      <div className="messagePanel">
        <ScrollableFeed>
          {displayMessages.length > 0 &&
            displayMessages.map(
              (e) =>
                e.message != "" && (
                  <div
                    style={{
                      padding: "1rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: e?.isLocal ? "flex-end" : "flex-start",
                        color: "black",
                      }}
                    >
                      {e?.isLocal ? (
                        <div
                          className="initials_container"
                          style={{ flexDirection: "row-reverse" }}
                        >
                          <Avatar>M</Avatar>
                          <text>Me</text>
                        </div>
                      ) : (
                        <div className="initials_container">
                          <Avatar>{e?.id.split("_")[1][0]}</Avatar>
                          <text>{e?.id.split("_")[1]}</text>
                        </div>
                      )}
                    </div>
                    <div
                      className="Message_Container"
                      style={{
                        alignSelf: e?.isLocal ? "flex-end" : "flex-start",
                        color: "black",
                      }}
                    >
                      {e?.message}
                    </div>
                  </div>
                )
            )}
        </ScrollableFeed>
      </div>
    </>
  );
}

export default ChatPanel;
