const initialState = {
  localAgoraData: {},
  remoteUsers: {},
};
export const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_LOCAL_AGORA_DATA": {
      const temp = {
        ...state,
        localAgoraData: {
          ...state["localAgoraData"],
          ...action.data,
        },
      };
      return temp;
    }
    case "REMOTE_USER": {
      const remoteUsers = { ...state["remoteUsers"] };
      remoteUsers[action.data.userID] = {
        ...remoteUsers[action.data.userID],
        ...action.data,
      };
      const temp = {
        ...state,
        remoteUsers: remoteUsers,
      };
      return temp;
    }
    case "REMOTE_USER_LEFT": {
      const remoteUsers = { ...state["remoteUsers"] };
      delete remoteUsers[action.data.id];
      const temp = {
        ...state,
        remoteUsers: remoteUsers,
      };
      return temp;
    }
    case "RESET_VIDEO_REDUCER": {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
};
