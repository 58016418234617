import React, { useEffect, useState } from "react";
import Options from "./assets/options.png";
import { useSelector } from "react-redux";
import "./ExperienceUI.css";
import Bar from "./UI_Components/Bar/Bar";
import Drawer from "./UI_Components/Chat/Drawer";
import MenuComponent from "../../../Components/Menu/Menu";
import { List, ListItemButton } from "@mui/material";
import optionsSelected from "./assets/optionsSelected.png";
import usersLogo from "./assets/usersLogo.png";
import DialogBox, { setonClose } from "../../../Components/DialogBox/DialogBox";
import Tutorial from "./UI_Components/Tutorial/Tutorial";
import Share from "./UI_Components/Share/Share";
import LeaderBoard from "./UI_Components/LeaderBoard/LeaderBoard";

import { useSnackbar } from "notistack";

function ExperienceUI({
  startAnim,
  switchZone,
  setupStadium,
  setupLounge,
  setupFanZone,
  setopenModal,
  openModal,
  takeSelfie,
  setwidth,
  width,
  modalWidth,
  setmodalWidth,
  isiframe,
  setisiframe,
  modalContent,
  setmodalContent,
}) {
  const VideoReducer = useSelector(
    (state) => state.VideoReducer.localAgoraData
  );
  const userCount = useSelector((state) => state.UserReducer.userCount);

  const [content, setcontent] = useState(null);
  const [isBottom, setisBottom] = useState(false);
  const [audioMuted, setaudioMuted] = useState(true);
  const [videoMuted, setvideoMuted] = useState(true);
  const [openChat, setopenChat] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [target, settarget] = useState(null);
  const [displayMessages, setdisplayMessages] = useState([]);
  const [shareOpen, setshareOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  window["setdisplayMessages"] = setdisplayMessages;

  useEffect(() => {
    if (VideoReducer.audioMuted != undefined) {
      setaudioMuted(VideoReducer.audioMuted);
    } else {
      setaudioMuted(true);
    }
    if (VideoReducer.videoMuted != undefined) {
      setvideoMuted(VideoReducer.videoMuted);
    } else {
      setvideoMuted(true);
    }
  }, [VideoReducer]);

  useEffect(() => {
    window["enqueueSnackbar"] = enqueueSnackbar;
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    settarget(event.target.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    settarget(null);
  };

  const onClose = () => {
    setopenModal(false);
  };
  const menu = () => {
    return (
      <List style={{ padding: 0, textTransform: "capitalize" }}>
        <ListItemButton
          id="menuOption"
          button
          onClick={() => {
            setopenChat(!openChat);
          }}
        >
          Chat
        </ListItemButton>
        <ListItemButton
          id="menuOption"
          button
          onClick={() => {
            takeSelfie();
          }}
        >
          Take Selfie
        </ListItemButton>
        <ListItemButton
          id="menuOption"
          button
          onClick={() => {
            setmodalWidth("xs");
            setmodalContent(
              <Share
                onClose={() => {
                  setopenModal(false);
                  setshareOpen(false);
                }}
              />
            );
            setisiframe(false);
            setopenModal(true);
            setshareOpen(true);
          }}
        >
          Share
        </ListItemButton>
        <ListItemButton
          button
          onClick={() => {
            setmodalWidth("md");
            setmodalContent(
              <LeaderBoard
                onClose={() => {
                  setopenModal(false);
                }}
              />
            );
            setisiframe(false);
            setopenModal(true);
          }}
        >
          LeaderBoard
        </ListItemButton>
        <ListItemButton
          button
          onClick={() => {
            setmodalWidth("md");
            setmodalContent(<Tutorial onClose={onClose} />);
            setopenModal(true);
            setisiframe(false);
          }}
        >
          Tutorial
        </ListItemButton>

        <ListItemButton>Help</ListItemButton>
      </List>
    );
  };

  return (
    <div className="exp_Container">
      <div
        id="video"
        style={{
          position: "fixed",
          top: 0,
          margin: "1rem",
          width: "300px",
          height: "300px",
        }}
      ></div>
      <DialogBox
        Content={modalContent}
        maxWidth={modalWidth}
        backgroundColor={"White"}
        open={openModal}
        iframe={isiframe}
        setopen={setopenModal}
      />
      <div className="usersCount_container">
        <img src={usersLogo} />
        <text>{userCount}</text>
      </div>
      <div className="option_container">
        {/* <button
          className="exp_BTN"
          onClick={() => {
            setPerspectiveChange(!perspectiveChange);
            switchCam();
          }}
        >
          {<img style={{ width: "25px" }} src={camSwitch} />}
        </button> */}
        <button
          id="Options"
          style={{
            backgroundColor: target == "Options" ? "#0156E1" : "white",
            width: "100%",
          }}
          className="exp_BTN"
          onClick={(e) => {
            handleClick(e);
            setcontent(menu);
            setwidth("221px");
            setisBottom(false);
          }}
        >
          {
            <img
              id="Options"
              style={{
                width: "18px",
              }}
              src={target == "Options" ? optionsSelected : Options}
            />
          }
        </button>
      </div>
      <Bar
        setopenModal={setopenModal}
        setopenChat={setopenChat}
        audioMuted={audioMuted}
        videoMuted={videoMuted}
        openChat={openChat}
        setcontent={setcontent}
        setwidth={setwidth}
        handleClick={handleClick}
        setisBottom={setisBottom}
        target={target}
        setmodalContent={setmodalContent}
        setmodalWidth={setmodalWidth}
        modalOpen={openModal}
        startAnim={startAnim}
        setupStadium={setupStadium}
        setupLounge={setupLounge}
        setupFanZone={setupFanZone}
        takeSelfie={takeSelfie}
        setdisplayMessages={setdisplayMessages}
        shareOpen={shareOpen}
        setshareOpen={setshareOpen}
        setisiframe={setisiframe}
      />
      <MenuComponent
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        Content={content}
        width={width}
        isBottom={isBottom}
        handleClose={handleClose}
      />

      <Drawer
        openChat={openChat}
        setopenchat={setopenChat}
        displayMessages={displayMessages}
        setdisplayMessages={setdisplayMessages}
      />
    </div>
  );
}

export default ExperienceUI;
