import React, { Component } from "react";
import * as BABYLON from "babylonjs";
import "babylonjs-loaders";

import Stadium from "../../assets/Gigabyte Stadium System Optimized.glb";
import stadiumMob from "../../assets/Stadium Mobile Optimized.glb";
import Lounge from "../../assets/Gigabyte Experience Lounge_01.glb";
import loungeMob from "../../assets/Gigabyte  Mobile Experience Lounge.glb";
import FanZone from "../../assets/MeetnGreet_Laptop1.glb";
import fanZoneMob from "../../assets/MeetnGreet_Mobile1.glb";

import BabylonScene from "./Scene";
import type { SceneEventArgs } from "./Scene";

import { store } from "../../redux/redux";

import { enableNavigation, pointerEvents } from "./Navigation";
import { addWaterShader } from "./MaterialCustomizer";
import {
  inputController,
  keyboardEvents,
  localPlayer,
  spawnLocalPlayer,
} from "./Player";
import {
  jerseyBTNs,
  setUpButtons,
  setupStadiumButton,
  setVideo,
  spawnJerseyButtons,
  viewerRef,
} from "./Interaction";

import fanZoneVideo from "../assets/Lsg Fan Zone Video.webm";
import aorusVideo from "../assets/Aorus Zingle.mp4";
import gigabyteVideo from "../assets/Gigabyte  Zingle.mp4";
import { UPDATE_ZONE } from "../../redux/actions/userDataAction";
import { joinChat, LeaveChat } from "../../Agora/AgoraChatService/agoraChat";
import {
  joinCall,
  leaveCall,
} from "../../Agora/AgoraVideoService/agoraVideoService";
import { FanZoneName } from "../../constants/constants";
import { joinRoom, localUserLeave, onAnimPlayed } from "./Network";

import DeviceDetector from "device-detector-js";

export let assetContainersHolder = [];
var localPlayerMaxHeight = 7.2;
export let currentLoadedModel = null;

export let loadStadium = null;
export let loadExpLounge = null;

export let temp;

type Props = {
  setisLoading: any,
  perspectiveChange: any,
  setPerspectiveChange: any,
  setisiframe: any,
  setmodalContent: any,
  setmodalWidth: any,
  setopenModal: any,
  setLoadingPercent: any,
  setFirstTime: any,
};

class Viewer extends Component<Props, {}> {
  constructor(props) {
    super(props);

    this.canvas = "";
    this.engine = "";
    this.scene = "";
    this.camera = "";
    this.freeCamera = "";
    this.device = null;
    loadStadium = this.setupStadium;
    loadExpLounge = this.setupLounge;
    this.light = "";
    this.music = "";
    this.zoneCallJoined = false;

    this.state = {};
  }

  onSceneMount = (e: SceneEventArgs) => {
    const { canvas, scene, engine } = e;
    this.canvas = canvas;
    this.engine = engine;
    this.scene = scene;

    temp = this.switchZone;

    this.setupCamera();
    this.setupLights();

    spawnLocalPlayer(
      store.getState().UserReducer.userData.UUID,
      this.camera,
      this.scene
    );

    this.setupStadium();

    keyboardEvents(this.scene);
    pointerEvents(this.scene, this.camera);
    engine.hideLoadingUI();
    this.props.setisLoading(true);

    engine.runRenderLoop(() => {
      if (scene) {
        if (localPlayer) {
          if (localPlayer.parent.position.y != localPlayerMaxHeight) {
            localPlayer.parent.position.y = localPlayerMaxHeight;
          }
        }
        inputController(this.camera, scene);
        scene.render();
      }
    });
  };

  setupCamera = () => {
    this.camera = new BABYLON.ArcRotateCamera(
      "Camera",
      1.57,
      1.2,
      7.135,
      new BABYLON.Vector3(-5, 15, 250),
      this.scene
    );
    this.camera.attachControl(this.canvas, true);
    this.camera.checkCollisions = true;
    // this.camera.inputs.remove(this.camera.inputs.attached.keyboard);
    this.camera.fov = 1;
    this.camera.minZ = 0;
    this.camera.position = new BABYLON.Vector3(-5, 15, 250);

    this.camera.upperRadiusLimit = 8;
    this.camera.lowerRadiusLimit = 0;

    this.camera.keysUp.pop(38);
    this.camera.keysDown.pop(40);
    this.camera.keysLeft.pop(37);
    this.camera.keysRight.pop(39);

    this.camera.keysLeft.push(68);
    this.camera.keysRight.push(65);

    this.freeCamera = new BABYLON.FreeCamera(
      "FPPCam",
      BABYLON.Vector3.Zero(),
      this.scene
    );

    this.freeCamera.attachControl(this.canvas, true);

    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    this.device = deviceDetector.parse(userAgent);
  };

  setupLights = () => {
    const light = new BABYLON.HemisphericLight(
      "light1",
      new BABYLON.Vector3(0, 1, 0),
      this.scene
    );
    light.intensity = 0.7;

    const images = [
      "./skybox/nx.jpg",
      "./skybox/py.jpg",
      "./skybox/nz.jpg",
      "./skybox/px.jpg",
      "./skybox/ny.jpg",
      "./skybox/pz.jpg",
    ];

    const envTex = new BABYLON.CubeTexture.CreateFromImages(images, this.scene);
    this.scene.environmentIntensity = 1.6;

    const skybox = this.scene.createDefaultSkybox(envTex, true, 10000);
    skybox.material.reflectionTexture.rotationY = 3;
  };

  setupStadium = async (setdisplayMessages) => {
    this.props.setisLoading(true);
    if (currentLoadedModel) {
      let childMeshes = currentLoadedModel.getChildMeshes();
      let node = this.scene.getTransformNodeByName("btnHolder");
      if (node) {
        node.dispose();
      }
      childMeshes.forEach((mesh) => {
        mesh?.material?.emissiveTexture?.dispose();
        mesh?.material?.dispose();
      });
      currentLoadedModel.dispose();
      currentLoadedModel = null;
    }

    let model = null;
    let number = 0;
    if (this.device.device.type === "desktop") {
      model = Stadium;
      number = 16908644;
    } else {
      model = stadiumMob;
      number = 8466432;
    }

    BABYLON.SceneLoader.ImportMesh(
      "",
      model,
      "",
      this.scene,
      (loadedMeshes) => {
        store.dispatch(UPDATE_ZONE("Stadium"));

        let rootMesh = loadedMeshes[0];
        currentLoadedModel = rootMesh;
        rootMesh.position = new BABYLON.Vector3.Zero();
        enableNavigation(rootMesh);

        localPlayer.parent.position = new BABYLON.Vector3(-5, 7.2, 250);
        localPlayer.parent.rotation = new BABYLON.Vector3.Zero();
        localPlayerMaxHeight = 7.2;
        this.camera.cameraRotation = new BABYLON.Vector3(0, Math.PI, 0);
        this.camera.alpha = 1.54;
        this.camera.beta = 1.164;

        addWaterShader(this.scene);

        spawnJerseyButtons(this.scene);

        const experienceZoneButtonMesh1 = this.scene.getMeshByName(
          "Portrait Button.001_primitive0"
        );

        const experienceZoneButtonMesh2 = this.scene.getMeshByName(
          "Portrait Button.002_primitive0"
        );

        setUpButtons(experienceZoneButtonMesh1, this.scene);
        setUpButtons(experienceZoneButtonMesh2, this.scene);

        // this.startBackgroundMusic();
        this.props.setisLoading(false);
        this.props.setFirstTime(false);

        // this.scene.debugLayer.show();
      },
      (e) => {
        console.log(e);
        this.props.setLoadingPercent(Math.floor((e.loaded / number) * 100));
      },
      (error) => {
        console.log(error);
      }
    );

    if (this.zoneCallJoined) {
      const roomid = store.getState().UserReducer.userData.Room_Id;
      await leaveCall();
      await LeaveChat();
      setdisplayMessages([]);
      await joinChat(roomid);
      await localUserLeave(
        store.getState().UserReducer.userData.UUID,
        "FanZone",
        this.scene
      );
      joinRoom(this.scene, store.getState().AvatarReducer, roomid);
      this.zoneCallJoined = false;
    }
  };

  setupLounge = async (setdisplayMessages) => {
    this.props.setisLoading(true);
    if (currentLoadedModel) {
      let childMeshes = currentLoadedModel.getChildMeshes();
      let node = this.scene.getTransformNodeByName("btnHolder");
      if (node) {
        node.dispose();
      }
      childMeshes.forEach((mesh) => {
        mesh?.material?.emissiveTexture?.dispose();

        mesh?.material?.dispose();
      });
      currentLoadedModel.dispose();
      currentLoadedModel = null;
    }
    const roomid = store.getState().UserReducer.userData.Room_Id;
    if (this.zoneCallJoined) {
      await leaveCall();
      await LeaveChat();
      setdisplayMessages([]);
      await joinChat(roomid);
      await localUserLeave(
        store.getState().UserReducer.userData.UUID,
        "FanZone",
        this.scene
      );
      joinRoom(this.scene, store.getState().AvatarReducer, roomid);
    }

    let loungeModel = null;
    if (this.device.device.type === "desktop") {
      loungeModel = Lounge;
    } else {
      console.log("smartphone");
      loungeModel = loungeMob;
    }

    BABYLON.SceneLoader.ImportMesh(
      "",
      loungeModel,
      "",
      this.scene,
      (loadedMeshes) => {
        store.dispatch(UPDATE_ZONE("Experience Lounge"));
        let rootMesh = loadedMeshes[0];
        currentLoadedModel = rootMesh;

        rootMesh.position = new BABYLON.Vector3(200, 0, 200);

        enableNavigation(rootMesh);

        this.zoneCallJoined = false;

        localPlayer.parent.position = new BABYLON.Vector3(200, 1.2, 220);
        localPlayer.parent.rotation = new BABYLON.Vector3(0, 0, 0);
        this.camera.alpha = 1.571;
        this.camera.beta = 1.281;
        localPlayerMaxHeight = 1.2;

        const floorMesh = this.scene.getMeshByName("Floor");
        floorMesh.material.roughness = 0.21;

        const stadiumButtonMesh = this.scene.getMeshByName(
          "Portrait Button_primitive0"
        );
        setupStadiumButton(stadiumButtonMesh, this.scene);

        let productMeshes = [
          {
            productMesh: "48 U Monitor",
            scale: { x: 1.5, y: 1.5, z: 1.5 },
            offset: { x: 0.6, y: 0.4, z: 0.1 },
            url: "https://gigabyte-model-products.web.app/F048Monitor.html",
          },
          {
            productMesh: "Aero 16",
            scale: { x: 0.8, y: 0.8, z: 0.8 },
            offset: { x: 0.2, y: 0.2, z: 0 },
            url: "https://gigabyte-model-products.web.app/Aero16.html",
          },
          {
            productMesh: "Aorus 15",
            scale: { x: 0.8, y: 0.8, z: 0.8 },
            offset: { x: 0.23, y: 0.3, z: 0 },
            url: "https://gigabyte-model-products.web.app/Aorus15.html",
          },
          {
            productMesh: "FV43",
            scale: { x: 1.2, y: 1.2, z: 1.2 },
            offset: { x: 0.5, y: 0.4, z: 0.1 },
            url: "https://gigabyte-model-products.web.app/FV43UMonitor.html",
          },
        ];

        productMeshes.forEach((mesh) => {
          this.spawnInfoButton(
            mesh.productMesh,
            mesh.scale,
            mesh.offset,
            mesh.url
          );
        });

        setVideo("Landscape_02", gigabyteVideo, this.scene);

        setVideo("Landscape_03", aorusVideo, this.scene);

        this.props.setisLoading(false);
      },
      (e) => {
        console.log(e);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  setupFanZone = async (setdisplayMessages) => {
    if (store.getState().UserReducer.userData.Role == "Visitor") {
      window.enqueueSnackbar(
        "Participate in Lucky Draw to win a chance to meet your stars",
        {
          variant: "info",
          autoHideDuration: 2000,
        }
      );
      return;
    }
    window.open("https://gigabyte-meetngreet.web.app");
  };

  startAnimation = (animName) => {
    for (let index = 0; index < localPlayer.animations.length; index++) {
      const element = localPlayer.animations[index];
      if (element.name === animName) {
        element.play(false);
        onAnimPlayed(animName);
        localPlayer["currentAnim"] = index;
        element.onAnimationGroupEndObservable.add(() => {
          localPlayer.animations[0].play(true);
        });
      } else {
        element.stop();
      }
    }
  };

  takeSelfie = () => {
    BABYLON.ScreenshotTools.CreateScreenshot(
      this.engine,
      this.camera,
      {
        width: this.engine.getRenderWidth(),
        height: this.engine.getRenderHeight(),
      },
      (selfie) => {
        this.fade(document.getElementById("studio"), 1000);

        setTimeout(() => {
          this.download(selfie, "selfie.png");
        }, 1250);
      }
    );
  };

  fade = (element, duration) => {
    (function increment(value = 0) {
      element.style.opacity = String(value);
      if (element.style.opacity !== "1") {
        setTimeout(() => {
          increment(value + 0.1);
        }, duration / 10);
      }
    })();
  };

  download = (dataurl, filename) => {
    const link = document.createElement("a");
    link.href = dataurl;
    link.download = filename;
    link.click();
  };

  switchCam = () => {
    if (this.props.perspectiveChange === true) {
      this.scene.activeCamera = this.freeCamera;
      this.freeCamera.position = new BABYLON.Vector3(0, 1, 0);
      this.freeCamera.rotation = new BABYLON.Vector3(0, Math.PI, 0);
    } else {
      this.scene.activeCamera = this.camera;
    }
  };

  startBackgroundMusic = () => {
    this.music = new BABYLON.Sound(
      "Music",
      "/StadiumCrowd_cXy4P_01.wav",
      this.scene,
      null,
      {
        loop: true,
        autoplay: true,
      }
    );

    this.music.play();
  };

  openModal = (url) => {
    this.props.setmodalContent(url);
    this.props.setisiframe(true);
    this.props.setmodalWidth("md");
    this.props.setopenModal(true);
  };

  spawnInfoButton = (mesh, scale, offset, url) => {
    let parentMesh = this.scene.getMeshByName(mesh);
    const infoBTN = BABYLON.MeshBuilder.CreateDisc(
      "info",
      { radius: 0.1, sideOrientation: BABYLON.Mesh.DOUBLESIDE },
      this.scene
    );

    const infoMat = new BABYLON.StandardMaterial("infoMat", this.scene);
    const tex = new BABYLON.Texture(
      "/Group 13007.png",
      this.scene,
      false,
      false
    );
    infoMat.diffuseTexture = tex;
    infoMat.opacityTexture = tex;
    infoMat.emissiveColor = BABYLON.Color3.White();

    infoBTN.material = infoMat;
    infoBTN.actionManager = new BABYLON.ActionManager(this.scene);
    infoBTN.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, () => {
        this.openModal(url);
      })
    );

    infoBTN.parent = parentMesh;
    infoBTN.scaling = new BABYLON.Vector3(scale.x, scale.y, scale.z);
    infoBTN.position = new BABYLON.Vector3(offset.x, offset.y, offset.z);

    let initialScale = 1;
    let finalScale = 1.25;

    const anim = BABYLON.Animation.CreateAndStartAnimation(
      "infoAnim",
      infoBTN,
      "scalingDeterminant",
      30,
      30,
      initialScale,
      finalScale,
      BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE,
      null
    );

    anim.onAnimationEndObservable.add(() => {
      if (anim.speedRatio === 1) {
        anim.speedRatio = -1;
      } else {
        anim.speedRatio = 1;
      }
    });
  };

  render() {
    return (
      <>
        <BabylonScene id="studio" onSceneMount={this.onSceneMount} />
      </>
    );
  }
}

export default Viewer;
