import AgoraRTM from "agora-rtm-sdk";
import { getchatToken, kickUser } from "../../API/API";
import { landingUrl } from "../../constants/constants";
import {
  CHAT_USER_ADDED,
  CHAT_USER_LEFT,
  MESSAGE_RECIEVED,
  MESSAGE_SENT,
  RESET_CHAT,
} from "../../redux/actions/agoraChatAction";
import {
  REMOTE_USER,
  REMOTE_USER_LEFT,
  UPDATE_LOCAL_AGORA_DATA,
} from "../../redux/actions/agoraVideoAction";
import { store } from "../../redux/redux";
import {
  leaveCall,
  toggleAudio,
  toggleVideo,
} from "../AgoraVideoService/agoraVideoService";

let options = {
  uid: "",
  token: "",
  ChannelName: "",
  Channel: "",
  APP_ID: "",
};

let client = null;

export const joinChat = async (ChannelName) => {
  const userData = store.getState().UserReducer.userData;
  options.uid = userData.UUID + "_" + userData.FirstName;
  options.ChannelName = ChannelName;
  // options.uid = (Math.random(0, 1) * 1000).toString();
  // options.ChannelName = "Hell";
  await getchatToken(options.uid).then((result) => {
    options.token = result.token;
    options.APP_ID = result.app_id;
    client = AgoraRTM.createInstance(options.APP_ID);
    options.Channel = client.createChannel(options.ChannelName);
    registerListeners();
    client.login({ uid: options.uid, token: options.token }).then(async (e) => {
      await options.Channel.join();
      store.dispatch(CHAT_USER_ADDED({ id: options.uid, isLocal: true }));
      await getAllMembers();
    });
  });
};

const registerListeners = () => {
  client.on("ConnectionStateChanged", (e) => {});
  options.Channel.on("MemberJoined", (id) => {
    store.dispatch(CHAT_USER_ADDED({ id: id, isLocal: false }));
  });
  options.Channel.on("ChannelMessage", (msg, id) => {
    const messageData = JSON.parse(msg.text);
    switch (messageData.type) {
      case "TEXT_MESSAGE": {
        store.dispatch(MESSAGE_RECIEVED({ id: id, message: messageData.Data }));
        break;
      }

      case "MUTE_USER": {
        if (messageData.id != options.uid) {
          store.dispatch(
            REMOTE_USER({
              userID: messageData.id,
              audioMuted: messageData.audioMuted,
            })
          );
        } else {
          toggleAudio();
        }
        break;
      }
      case "CAMERA_OFF_USER": {
        if (messageData.id != options.uid) {
          store.dispatch(
            REMOTE_USER({
              userID: messageData.id,
              videoMuted: messageData.videoMuted,
            })
          );
        } else {
          toggleVideo();
        }
        break;
      }
      case "KICK_USER": {
        if (messageData.id === options.uid) {
          leaveCall().then(async () => {
            LeaveChat().then(async () => {
              alert("You have been Kicked");
              const uid = options.uid.split("_")[0];
              await kickUser(uid);
              window.location.href = landingUrl;
            });
          });
        }
        break;
      }
    }
  });
  options.Channel.on("MemberLeft", (id) => {
    store.dispatch(CHAT_USER_LEFT({ id: id }));
  });
};

export const sendMessage = (msg) => {
  if (msg != "") {
    const message = JSON.stringify({
      type: "TEXT_MESSAGE",
      Data: msg,
    });
    options.Channel.sendMessage({ text: message }).then(() => {
      store.dispatch(MESSAGE_SENT({ id: options.uid, message: msg }));
    });
  }
};

export const sendActionMessage = async (msg) => {
  options.Channel.sendMessage({ text: JSON.stringify(msg) });
};

export const LeaveChat = async () => {
  store.dispatch(RESET_CHAT());
  await options.Channel.leave();
  await client.logout();
};

const getAllMembers = async () => {
  const members = await options.Channel.getMembers();
  members
    .filter((member) => member !== options.uid)
    .forEach((member) =>
      store.dispatch(
        CHAT_USER_ADDED({
          id: member,
          isLocal: false,
        })
      )
    );
};
