import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import "./Share.css";
import CloseIcon from "@mui/icons-material/Close";
import { landingUrl } from "../../../../../constants/constants";

const shareurl = landingUrl;

function Share({ onClose }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 40,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: "black",
            fontSize: "1em",
            fontWeight: "500",
          }}
        >
          Share
        </div>
        <button
          className="closeBTN"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon fontSize="small" style={{ color: "#333333" }} />
        </button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          color: "#333333",
          fontWeight: "bold",
          fontSize: "0.9em",
        }}
      >
        <FacebookShareButton
          url={shareurl}
          quote="LataMangeskar #MetaMelodies"
          hashtag="#MetaMelodies"
        >
          <FacebookIcon round={true}></FacebookIcon>
          <div>Facebook</div>
        </FacebookShareButton>
        <WhatsappShareButton
          url={shareurl}
          quote="LataMangeskar #MetaMelodies"
          hashtag="#MetaMelodies"
        >
          <WhatsappIcon round={true}></WhatsappIcon>
          <div>Whats App</div>
        </WhatsappShareButton>
        <TwitterShareButton
          url={shareurl}
          quote="LataMangeskar #MetaMelodies"
          hashtag="#MetaMelodies"
        >
          <TwitterIcon round={true}></TwitterIcon>
          <div>Twitter</div>
        </TwitterShareButton>
        <EmailShareButton
          url={shareurl}
          quote="LataMangeskar #MetaMelodies"
          hashtag="#MetaMelodies"
        >
          <EmailIcon round={true}></EmailIcon>
          <div>Email</div>
        </EmailShareButton>
      </div>
      <div style={{ width: "100%", display: "flex" }}>
        <input className="ShareInput" autoFocus={true} value={shareurl} />
        <button
          className="copyButton"
          onClick={() => {
            navigator.clipboard.writeText(shareurl);
          }}
        >
          COPY
        </button>
      </div>
    </div>
  );
}

export default Share;
