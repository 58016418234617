import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateData } from "../../../API/API";
import { ADD_USER_INFO } from "../../../redux/actions/userDataAction";
import { store } from "../../../redux/redux";
import "./UserDetails.css";
import validator from "validator";

function UserDetails({ number, loading, setloading }) {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [city, setcity] = useState("");
  const navigate = useNavigate();
  const [invalidMail, setinvalidMail] = useState(false);

  return (
    <>
      <div className="user_input_Holder">
        <input
          type={"text"}
          placeholder="First Name"
          onChange={(e) => {
            setfirstname(e.target.value);
          }}
        />
        <input
          type={"text"}
          placeholder="Last Name"
          onChange={(e) => {
            setlastname(e.target.value);
          }}
        />
        {invalidMail && <label style={{ color: "red" }}>*Invalid Mail</label>}
        <input
          type={"text"}
          placeholder="Your Email Address"
          onChange={(e) => {
            const email = e.target.value;
            setinvalidMail(!validator.isEmail(email));
            setemail(email);
          }}
        />
        <input
          type={"text"}
          placeholder="City"
          onChange={(e) => {
            setcity(e.target.value);
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <input type={"checkbox"} />
          <text>Subscribe for GIGABYTE newsletter</text>
        </div>
        <button
          className="custom-btn BTN"
          disabled={
            firstname == "" ||
            lastname == "" ||
            city == "" ||
            email == "" ||
            loading ||
            invalidMail
          }
          onClick={() => {
            setloading(true);
            updateData(firstname, lastname, email, city, number)
              .then((e) => {
                store.dispatch(ADD_USER_INFO(e));
                navigate("/avatar");
                setloading(false);
              })
              .catch((e) => {
                console.log(e);
                setloading(false);
              });
          }}
        >
          {loading ? (
            <CircularProgress size={18} style={{ color: "white" }} />
          ) : (
            " Enter Metaverse"
          )}
        </button>
      </div>
    </>
  );
}

export default UserDetails;
