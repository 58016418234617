const MessageInitialState = {
  Messages: {
    Users: {},
    message: {},
  },
};

export const messageReducer = (state = MessageInitialState, action) => {
  switch (action.type) {
    case "CHAT_USER_ADDED": {
      let temp = {
        ...state,
      };
      temp.Messages = {
        ...temp.Messages,
        Users: {
          ...temp.Messages.Users,
          [action.data.id]: "",
        },
      };

      return {
        ...state,
        Messages: Object.assign({}, temp.Messages),
      };
    }
    case "MESSAGE_RECIEVED": {
      let temp = {
        ...state,
      };
      temp.Messages = {
        ...temp.Messages,
        message: {
          [action.data.id]: {
            id: action.data.id,
            isLocal: false,
            message: action.data.message,
          },
        },
      };
      return {
        ...state,
        Messages: Object.assign({}, temp.Messages),
      };
    }
    case "MESSAGE_SENT": {
      let temp = {
        ...state,
      };
      temp.Messages = {
        ...temp.Messages,
        message: {
          [action.data.id]: {
            id: action.data.id,
            isLocal: true,
            message: action.data.message,
          },
        },
      };
      return {
        ...state,
        Messages: Object.assign({}, temp.Messages),
      };
    }
    case "CHAT_USER_LEFT": {
      let temp = {
        ...state,
      };
      const users = temp.Messages.Users;
      delete users[action.data.id];
      temp.Messages = {
        ...temp.Messages,
        Users: Object.assign({}, users),
      };

      return temp;
    }
    case "RESET_CHAT": {
      return {
        ...MessageInitialState,
      };
    }
    default: {
      return state;
    }
  }
};
