import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { authenticateOTP, sendOTP } from "../../../API/API";
import {
  AUTH_SUCCESS,
  CODE_NOT_REGISTERED,
  SUCCESS_CODE,
} from "../../../constants/constants";
import { ADD_USER_INFO } from "../../../redux/actions/userDataAction";
import { store } from "../../../redux/redux";
import "./Authenticate.css";

function Authenticate({
  number,
  setotpsent,
  setisRegistered,
  loading,
  setloading,
  setauth,
}) {
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [error, seterror] = useState("");
  const handleChange = (otp) => setOTP(otp);
  const [resetDisabled, setresetDisabled] = useState(false);

  useEffect(() => {
    if (resetDisabled) {
      setTimeout(() => {
        setresetDisabled(false);
      }, 10000);
    }
  }, [resetDisabled]);

  return (
    <>
      <header>Verify using OTP</header>
      <text>
        <span>Enter</span>
        <span style={{ color: "black", fontWeight: "bold" }}> 6 digit OTP</span>
        <span> we sent on {number}</span>
      </text>
      <OtpInput
        value={OTP}
        onChange={handleChange}
        numInputs={6}
        separator={" "}
        inputStyle={{ color: "#CACAD1" }}
        className="otp_input"
        containerStyle={{ gap: "1em" }}
        isInputNum={true}
        shouldAutoFocus={true}
      />
      {error != "" && <text style={{ color: "#CB2626" }}>*{error} </text>}
      <button
        disabled={OTP.length < 6 || loading}
        className="BTN custom-btn"
        onClick={() => {
          setloading(true);
          authenticateOTP(number, OTP).then((e) => {
            if (e.responseCode == AUTH_SUCCESS) {
              const temp = e;
              delete e["responseCode"];
              delete e["message"];
              store.dispatch(ADD_USER_INFO(temp));
              setauth(true);
              navigate("/avatar");
            } else if (e.responseCode == CODE_NOT_REGISTERED) {
              setisRegistered(false);
            } else {
              seterror(e.message);
            }
            setloading(false);
          });
        }}
      >
        {loading ? (
          <CircularProgress size={18} style={{ color: "white" }} />
        ) : (
          "Continue"
        )}
      </button>
      <div
        className="ResendOTP"
        data-resetdisabled={resetDisabled}
        onClick={() => {
          if (!resetDisabled) {
            setresetDisabled(true);
            sendOTP(number).then((e) => {
              if (e.responseCode == SUCCESS_CODE) {
                alert(e.message);
                setotpsent(true);
              }
              setOTP("");
            });
          }
        }}
      >
        Resend OTP
      </div>
      <div
        className="ResendOTP"
        onClick={() => {
          setotpsent(false);
        }}
      >
        Change Number
      </div>
    </>
  );
}

export default Authenticate;
