export const UPDATE_LOCAL_AGORA_DATA = (userData) => ({
  type: "UPDATE_LOCAL_AGORA_DATA",
  data: userData,
});
export const REMOTE_USER = (userData) => ({
  type: "REMOTE_USER",
  data: userData,
});
export const REMOTE_USER_LEFT = (userData) => ({
  type: "REMOTE_USER_LEFT",
  data: userData,
});
export const RESET_VIDEO_REDUCER = (userData) => ({
  type: "RESET_VIDEO_REDUCER",
  data: userData,
});
